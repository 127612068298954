<div class="main-dev d-flex flex-column gap-3">
    <mat-form-field appearance="fill" class="input-group">
        <mat-label>Account-Name</mat-label>
        <input matInput [(ngModel)]="data.accountname">
    </mat-form-field>
    <mat-form-field appearance="fill" class="input-group">
        <mat-label>User-Name</mat-label>
        <input matInput [(ngModel)]="data.username">
    </mat-form-field>
    <mat-form-field appearance="fill" class="input-group">
        <mat-label>Password</mat-label>
        <input type="password" matInput [(ngModel)]="data.password">
    </mat-form-field>
    <span class="mat-caption">
        Technisch bedingt ist es leider nötig, die WebFleet Login-Daten <strong>unverschlüsselt</strong> in IO-ELON abzuspeichern.<br>
        Bitte nur zufalls-generierte Passwörter nutzen, die nicht an verschiedenen Orten verwendet werden.
    </span>
</div>
