import {Component, Input, OnInit} from '@angular/core';
import {
    DynamicLoadType,
    ExecuteLoadAddOrUpdate,
    GraphPoint,
    Meter,
    PowerSupply,
    TimeZoneName
} from '@io-elon-common/frontend-api';
import {IEditForm} from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {BehaviorSubject} from 'rxjs';
import {MeterService} from '../../../meter/service/meter.service';
import {LoadService} from '../../service/load.service';
import {AuthService} from "../../../../shared/guards/auth.service";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {
    formatValidationMessage,
    KnownValidationErrors
} from "../../../../shared/components/validation-error/known-validation-error";
import {DialogType} from '../../../../shared/components/help-box/dialogType';
import {SystemService} from "../../../../services/api-handlers/system.service";

const DAY = 24 * 60 * 60 * 1000;



const NO_ERROR = null;

@Component({
    selector: 'app-edit-load-dialog',
    templateUrl: './edit-load-dialog.component.html',
    styleUrls: ['./edit-load-dialog.component.scss']
})
export class EditLoadDialogComponent implements IEditForm<ExecuteLoadAddOrUpdate>, OnInit {
    protected readonly DynamicLoadType = DynamicLoadType;
    protected readonly RepeatIntervalEnum = ExecuteLoadAddOrUpdate.RepeatIntervalEnum;


    @Input()
    public possiblePowerSupplies!: PowerSupply[];
    @Input()
    public data!: ExecuteLoadAddOrUpdate;
    @Input()
    public edit!: boolean;
    @Input()
    public loadId?: number

    public maxMeterSlidingMaxTimeMinutes!: number;

    public meters!: BehaviorSubject<Meter[] | undefined>;
    public isDev: boolean;

    public type: FormControl<DynamicLoadType | null> = new FormControl(null);
    public graphPointList: FormControl<Array<GraphPoint> | null> = new FormControl(null);
    public meterId: FormControl<number | null | undefined> = new FormControl(null);
    public name: FormControl<string | null> = new FormControl(null, Validators.required);
    public powerSupplyId: FormControl<number | null> = new FormControl(null, Validators.required);
    public repeatInterval: FormControl<ExecuteLoadAddOrUpdate.RepeatIntervalEnum | null | undefined> = new FormControl(null);
    public zone: FormControl<TimeZoneName | null | undefined> = new FormControl(null)
    public fallbackI1: FormControl<number | null | undefined> = new FormControl(null);
    public fallbackI2: FormControl<number | null | undefined> = new FormControl(null);
    public fallbackI3: FormControl<number | null | undefined> = new FormControl(null);
    public readonly DialogType = DialogType;

    public slidingMaxMinutes: FormControl<number | null | undefined> = new FormControl(null,_ => {
            const val = this.slidingMaxMinutes?.getRawValue();
            if(val !== 0 && !val) {
                return { required: true }
            }
            const max = this.type.getRawValue() === DynamicLoadType.MeterBasedDynamicLoad ? this.systemInfo.getSystemInfoSync()!.meterLoadSlidingMaxMinutesMax : (60 * 24);
            if(val > max) {
                return { max: { max: max, actual: val } }
            }
            return NO_ERROR;
        }
    );

    public formGroup = new FormGroup({
        graphPointList: this.graphPointList,
        meterId: this.meterId,
        name: this.name,
        powerSupplyId: this.powerSupplyId,
        repeatInterval: this.repeatInterval,
        type: this.type,
        zone: this.zone,
        fallbackI1: this.fallbackI1,
        fallbackI2: this.fallbackI2,
        fallbackI3: this.fallbackI3,
        slidingMaxMinutes: this.slidingMaxMinutes
    });

    public constructor(
        private readonly meterService: MeterService,
        private readonly loadService: LoadService,
        private readonly authService: AuthService,
        private readonly systemInfo: SystemService
    ) {
        this.isDev = authService.isDeveloper();
    }

    public ngOnInit(): void {
        this.maxMeterSlidingMaxTimeMinutes = this.systemInfo.getSystemInfoSync()!.meterLoadSlidingMaxMinutesMax;
        this.meters = this.meterService.getAll();

        if(this.edit) {
            this.type.disable();
        }

        this.type.valueChanges.subscribe(t => {
            switch (t) {
                case DynamicLoadType.UserDefinedLoad:
                    this.meterId.setValidators([]);
                    this.fallbackI1.setValidators([]);
                    this.fallbackI2.setValidators([]);
                    this.fallbackI3.setValidators([]);
                    break;
                case DynamicLoadType.MeterBasedDynamicLoad:
                    this.meterId.setValidators((ctrl) => {
                        if(this.type.getRawValue() !== DynamicLoadType.MeterBasedDynamicLoad) {
                            return NO_ERROR;
                        }
                        const meterId = ctrl.getRawValue();
                        if(typeof meterId === "number" && meterId > 0) {
                            return NO_ERROR;
                        }
                        return {
                            errorText: "Kein Messgerät ausgewählt"
                        }
                    });
                    this.fallbackI1.setValidators((ctrl) => this.validateFallback(ctrl as FormControl<number | null | undefined>))
                    this.fallbackI2.setValidators((ctrl) => this.validateFallback(ctrl as FormControl<number | null | undefined>))
                    this.fallbackI3.setValidators((ctrl) => this.validateFallback(ctrl as FormControl<number | null | undefined>))
                    break;
            }
            this.meterId.updateValueAndValidity()
            this.fallbackI1.updateValueAndValidity()
            this.fallbackI2.updateValueAndValidity()
            this.fallbackI3.updateValueAndValidity()
            this.slidingMaxMinutes.updateValueAndValidity()
            this.formGroup.updateValueAndValidity()
        })

        this.type.setValue(this.data.type);
        this.graphPointList.setValue(this.data.graphPointList);
        this.meterId.setValue(this.data.meterId);
        this.name.setValue(this.data.name);
        this.powerSupplyId.setValue(this.data.powerSupplyId);
        this.repeatInterval.setValue(this.data.repeatInterval);
        this.zone.setValue(this.data.zone);
        this.fallbackI1.setValue(this.data.fallbackI1);
        this.fallbackI2.setValue(this.data.fallbackI2);
        this.fallbackI3.setValue(this.data.fallbackI3);

        this.slidingMaxMinutes.setValue(this.data.slidingMaxMinutes);

        this.graphPointList.valueChanges.subscribe(() => this.data.graphPointList = this.graphPointList.getRawValue() as Array<GraphPoint>);
        this.meterId.valueChanges.subscribe(() => this.data.meterId = this.meterId.getRawValue() as number | undefined);
        this.name.valueChanges.subscribe(() => this.data.name = this.name.getRawValue() as string);
        this.powerSupplyId.valueChanges.subscribe(() => this.data.powerSupplyId = this.powerSupplyId.getRawValue() as number);
        this.repeatInterval.valueChanges.subscribe(() => this.data.repeatInterval = this.repeatInterval.getRawValue() as ExecuteLoadAddOrUpdate.RepeatIntervalEnum | undefined);
        this.type.valueChanges.subscribe(() => this.data.type = this.type.getRawValue() as DynamicLoadType);
        this.zone.valueChanges.subscribe(() => this.data.zone = this.zone.getRawValue() as TimeZoneName | undefined);
        this.fallbackI1.valueChanges.subscribe(() => {
            if(this.data.fallbackI1 == this.data.fallbackI2) {
                this.fallbackI2.setValue(this.fallbackI1.getRawValue());
            }
            if(this.data.fallbackI1 == this.data.fallbackI3) {
                this.fallbackI3.setValue(this.fallbackI1.getRawValue());
            }
            this.data.fallbackI1 = this.fallbackI1.getRawValue() as number | undefined
        });
        this.fallbackI2.valueChanges.subscribe(() => this.data.fallbackI2 = this.fallbackI2.getRawValue() as number | undefined);
        this.fallbackI3.valueChanges.subscribe(() => this.data.fallbackI3 = this.fallbackI3.getRawValue() as number | undefined);
        this.slidingMaxMinutes.valueChanges.subscribe(() => this.data.slidingMaxMinutes = this.slidingMaxMinutes.getRawValue() as number);
    }


    private validateFallback(ctrl: FormControl<number | null | undefined>) {
        const type = this.type.getRawValue();
        if(!type) {
            return {errorText: "Typ ist nicht ausgewählt!"}
        }
        switch (type) {
            case DynamicLoadType.UserDefinedLoad:
                return NO_ERROR;
            case DynamicLoadType.MeterBasedDynamicLoad:
                const amps = ctrl.getRawValue();
                if(typeof amps !== "number") {
                    return {errorText: "Fallback ist keine Zahl!"}
                }
                if(amps < 0) {
                    return {errorText: "Fallback darf nicht negativ sein!"}
                }
                return NO_ERROR;
        }
        return {errorText: "Typ ist unbekannt!"}
    }

    public async validate(): Promise<string[]> {
        this.formGroup.markAllAsTouched()

        this.formGroup.updateValueAndValidity({onlySelf: false, emitEvent: true});

        await new Promise((resolve) => {
            if (this.formGroup.pending) {
                let sub = this.formGroup.statusChanges.subscribe((res) => {
                    if(!this.formGroup.pending) {
                        sub.unsubscribe();
                        resolve(null);
                    }
                });
            } else {
                resolve(null);
            }
        });

        if(!this.formGroup.invalid){
            return [];
        }

        console.log(this.formGroup);

        const validationResult = [
            ...this.formatErrors(this.formGroup),
            ...Object.values(this.formGroup.controls).map(c => this.formatErrors(c)).flat()
        ];
        console.log(validationResult);
        return validationResult
    }

    private formatErrors(ctrl: AbstractControl): string[] {
        if (!ctrl.errors) {
            return [];
        }
        const errors = ctrl.errors as KnownValidationErrors;
        const keys = Object.keys(errors) as Array<keyof KnownValidationErrors>;
        return keys.map(k => formatValidationMessage(k, errors[k]));
    }

    public updateGraphLen(): void {
        switch (this.data.repeatInterval) {
            case 'DAY':
                this.data.graphPointList = this.data.graphPointList.filter(v => v.tst < DAY);
                break;
            case 'WEEK':
                for(let day = 0; day < 7; day++){
                    if(!this.hasMidnightBefore(day)) {
                        this.data.graphPointList.push({
                            tst: day * DAY,
                            l1: 300, l2: 300, l3: 300
                        });
                    }
                    if(!this.hasDataForDay(day)) {
                        this.data.graphPointList.push(...LoadService.generateDefaultGraphPoints(day, false));
                    }
                }
        }
    }

    private hasMidnightBefore(day: number): boolean {
        return this.data.graphPointList.some(v => v.tst === (day * DAY));
    }

    private hasDataForDay(day: number): boolean {
        return this.data.graphPointList.some(v => v.tst > (day * DAY)  && v.tst < ((day + 1) * DAY));
    }
}
