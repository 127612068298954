<div *ngIf="(allMeters | async) as allMeters; else loading">
    <mat-card appearance="outlined" class="p-0" *ngIf="noBasisMeter.length > 0">
        <mat-card-header>
            <mat-card-title><h2>Zähler sind keinem Standort zugeordnet</h2></mat-card-title>
        </mat-card-header>
        <app-meter-no-fleet-table [meters]="noBasisMeter" (updateListEvent)="updateMeterList()"></app-meter-no-fleet-table>
    </mat-card>

    <mat-card appearance="outlined" id="evse-table-container" class="p-0">
        <app-meter-table [meters]="currentBasisMeters"></app-meter-table>
    </mat-card>

    <div class="buttons d-flex flex-row justify-content-end gap-3">
        <button [disabled]="!canAdd" mat-raised-button color="primary" (click)="newFormulaMeter()">Neue Formel</button>
        <button [disabled]="!canAdd" mat-raised-button color="primary" (click)="newMeter()">Neuer Zähler</button>
    </div>
</div>
<ng-template #loading>
    Loading...
</ng-template>
