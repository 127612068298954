import {Injectable} from '@angular/core';
import {
    ExecuteVehicleAddOrUpdate, Reservation,
    ReservationInstance, RioAssetDataList,
    Vehicle,
    VehicleHistory, VehicleSetSohResult
} from '@io-elon-common/frontend-api';
import {ApiService} from '../../../services/api-handlers/api.service';
import {DialogHandler} from '../../../services/api-handlers/dialog-handler';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {EditVehicleDialogComponent} from '../dialogs/edit-vehicle-dialog/edit-vehicle-dialog.component';
import {TDialogOptions} from '../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {BehaviorSubject} from 'rxjs';
import {CacheUpdater} from '../../../services/api-handlers/cacheManager';
import {
    ExecuteVehicleAddOrUpdateConnection
} from '@io-elon-common/frontend-api/lib/model/executeVehicleAddOrUpdateConnection';
import {DongleService} from '../../dongle/service/dongle.service';
import {TeslaApiDatasourceService} from '../../tesla/service/tesla-api-datasource.service';
import {ApiHandler} from "../../../services/api-handlers/api-handler";
import {POLL_INTERVALS} from "../../../app.module";
import {WebfleetAccountService} from '../../webfleet/service/webfleet-account.service';

const PIXEL_PER_DOT = 10;

@Injectable({
    providedIn: 'root'
})
export class VehicleService extends DialogHandler<Vehicle, ExecuteVehicleAddOrUpdate, ExecuteVehicleAddOrUpdate, {}, number, void> {
    private reservationsCache: CacheUpdater<Reservation[], number>
    private reservationInstancesCache: CacheUpdater<ReservationInstance[], { id: number, start: number, end: number }>

    constructor(
        apiService: ApiService,
        toastr: ToastrService,
        dialog: MatDialog,
        dialogService: DialogService,
        private dongleService: DongleService,
        private teslaDatasourceService: TeslaApiDatasourceService,
        private readonly webFleetService : WebfleetAccountService
    ) {
        super(apiService, "Vehicle", toastr, dialog, dialogService, POLL_INTERVALS.vehicle);
        this.reservationsCache = this.createManagedCache((obj, id) => obj.id === id)
        this.reservationInstancesCache = this.createManagedCache(
            (obj, id) => obj.id.start === id.start && obj.id.end === id.end && obj.id.id === id.id);
    }

    public getReservationsPromise(vehicleId: number, showAlerts = true): Promise<Reservation[] | undefined> {
        return this.wrapInPromise(() => this.getReservations(vehicleId, showAlerts));
    }

    public getReservationsByTimePromise(vehicleId: number, start: number, end: number, showAlerts = true): Promise<ReservationInstance[] | undefined> {
        return this.wrapInPromise(() => this.getReservationsByTime(vehicleId, start, end, showAlerts));
    }

    public getReservationsByTime(vehicleId: number, start: number, end: number, showAlerts = true): BehaviorSubject<ReservationInstance[] | undefined> {
        return this.reservationInstancesCache.getOrCreateGet(
            {
                id: vehicleId,
                start,
                end
            },
            ()=>this.apiService.getVehicleReservationInstancesInTimeRange(showAlerts, vehicleId, start, end, undefined, undefined, ApiHandler.customerId).toPromise().then(list => list.list)
        ).data;
    }

    public getReservations(vehicleId: number, showAlerts = true): BehaviorSubject<Reservation[] | undefined> {
        return this.reservationsCache.getOrCreateGet(
            vehicleId,
            ()=>this.apiService.getVehicleReservations(showAlerts, vehicleId, undefined, undefined, ApiHandler.customerId).toPromise().then(list => list.list)
        ).data
    }

    public getRioAssetsByVin(vin: string, showAlerts = true): Promise<RioAssetDataList> {
        return this.apiService.getRioInfo(showAlerts, vin, undefined, undefined, ApiHandler.customerId).toPromise()
    }

    public async getHistoryOfVehicle(vehicleId: number, start: number | Date, end: number | Date, showAlerts = true): Promise<VehicleHistory> {
        start = typeof start === 'number' ? Math.ceil(start) : start.getTime();
        end = typeof end === 'number' ? Math.floor(end) : end.getTime();
        const zoom = Math.ceil((end - start) / 1000 / window.innerWidth * PIXEL_PER_DOT);

        return await this.apiService.getVehicleHistory(showAlerts, vehicleId, start, end, zoom, undefined, undefined, ApiHandler.customerId).toPromise();
    }

    public async setSoh(veicleId: number, soh: number): Promise<VehicleSetSohResult> {
        return await this.apiService.setSoh(true, veicleId, {soh}).toPromise()
    }

    protected async getEditConfig(vehicle: Vehicle): Promise<TDialogOptions<ExecuteVehicleAddOrUpdate, EditVehicleDialogComponent>> {
        return {
            headline: "Fahrzeug bearbeiten",
            component: EditVehicleDialogComponent,
            executeCallback: editResult => this.update(vehicle.id, editResult),
            editElement: {
                connection: await this.getConnection(vehicle),
                defaultTargetSoc: vehicle.defaultTargetSoc || 50,
                confMaxAmps: vehicle.confMaxAmps || 20,
                confMinAmps: vehicle.confMinAmps || 6,
                localId: vehicle.localId || "",
                name: vehicle.name || "",
                fleetId: vehicle.fleet.id,
                numberPlate: vehicle.numberPlate || "",
                type: vehicle.type,
                mainCard: vehicle.mainCard,
                canPauseCharging: vehicle.canPauseCharging
            },
            extraParams: {
                edit: true
            }
        };
    }

    private async getConnection(vehicle: Vehicle): Promise<ExecuteVehicleAddOrUpdateConnection> {
        if(!vehicle.dataSource) {
            return {};
        }

        if(/Dongle/i.test(vehicle.dataSource.name)) {
            return {
                dongle: {
                    dataSourceId: vehicle.dataSource.id
                }
            }
        } else if (vehicle.dataSource.name.startsWith("Mercedes Benz API")) {
            return {
                mercedes: {
                    vin: vehicle.dataSource.name.substring(vehicle.dataSource.name.indexOf('(')+1,
                    vehicle.dataSource.name.indexOf(')'))
                }
            }
        } else if (vehicle.dataSource.name.startsWith("Tronity")) {
            return {
                tronity: {}
            }
        } else if (vehicle.dataSource.name.startsWith("RIO")) {
            return {
                rio: {
                    rioAssetId: vehicle.dataSource.name.substring(vehicle.dataSource.name.indexOf('(')+1,
                        vehicle.dataSource.name.indexOf(')'))
                }
            }
        } else if (vehicle.dataSource.name.startsWith("S-Tec")) {
            const ids = vehicle.dataSource.name.substring(vehicle.dataSource.name.indexOf('(') + 1, vehicle.dataSource.name.indexOf(")"));
            const idArr = ids.split("/");
            return {
                sTec: {
                    accountId: parseInt(idArr[0]),
                    troId: idArr[1]
                }
            }
        } else if (/Free to Move API/.test(vehicle.dataSource.name)) {
            return {
                free2move: {
                    dataSourceId: vehicle.dataSource.id
                }
            }
        } else if (vehicle.dataSource.name.startsWith("Kuantic")) {
            return {
                kuantic: {
                    vin: vehicle.dataSource.vendorVehicleId ?? ""
                }
            }
        } else if(vehicle.dataSource.name.startsWith("Webfleet")) {
           const ds = await this.webFleetService.getWebfleetDatasource(vehicle.dataSource.id);
           return {
               webfleet: {
                   webfleetId: ds.webfleetVehicleId,
                   accountId: ds.webfleetAccountId
               }
           }
        }
        else {
            const ds = await this.wrapInPromise(() => {
                if(vehicle.dataSource) {
                    return this.teslaDatasourceService.get(vehicle.dataSource.id)
                }
                throw new Error("Datasource got undefined short ago")
            });
            if(!ds) {
                return {};
            }
            return {
                tesla: {
                    teslaVehicleId: ds.teslaVehicleId,
                    teslaAccountId: ds.teslaAccountId,
                }
            }
        }
    }

    protected getNewConfig(fleetId: number): TDialogOptions<ExecuteVehicleAddOrUpdate, EditVehicleDialogComponent> {
        return {
            headline: "Fahrzeug anlegen",
            component: EditVehicleDialogComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                connection: {},
                defaultTargetSoc: 50,
                confMaxAmps: 20,
                confMinAmps: 6,
                localId: '',
                name: '',
                fleetId,
                numberPlate: '',
                type: '',
                canPauseCharging: true
            },
            extraParams: {
                edit: false
            }
        };
    }
}
