@if(show404) {
    <app-404 msg="Ladepunkt nicht gefunden"></app-404>
} @else {
    <div *ngIf="(evse | async) as evse; else loading">
        <ng-container *ngIf="(vehicles | async) as vehicles; else loading">
            <div class="d-flex flex-column gap-3">
                <app-boxes [boxes]="boxes"></app-boxes>
                <div class="d-flex flex-row align-content-between w-100 widget-row gap-2">
                    <div>
                        <button mat-icon-button [matMenuTriggerFor]="actionsMenu"><i class="fas fa-gear"></i></button>
                        <mat-menu #actionsMenu="matMenu">
                                <button mat-menu-item type="submit" (click)="details()">Details</button>
                                <button [disabled]="!evse.canEdit" mat-menu-item type="submit" (click)="edit()">Bearbeiten</button>
                                <button [disabled]="!evse.canEdit" mat-menu-item type="submit" (click)="actions()">Aktionen</button>
                                <button mat-menu-item type="submit" (click)="showLog()" *ngIf="supportsLog">Protokoll</button>
                                <button mat-menu-item type="submit" matTooltip="Validierung" [disabled]="evse.liveData.validationResult.length === 0" (click)="handleValidation()">Konfiguration Prüfung</button>
                                @if(evse.actorKey==='de.iodynamics.elon.backend.service.ocpp.orm.OcppActor') {
                                    @if(logUploadDisabled()) {
                                        <div mat-menu-item disabled="" matTooltip="Nur für ConnectorId 1 möglich">Diagnose-Uploads</div>
                                    } @else {
                                        <button mat-menu-item (click)="showUploads()">Diagnose-Uploads</button>
                                    }
                                }
                                <button [disabled]="!evse.canDelete" mat-menu-item type="submit" (click)="delete()">Löschen</button>
                        </mat-menu>
                    </div>
                    <div class="evse-image-container">
                        <img class="center" *ngIf="evse" src="/img/evses/{{evse.img}}" alt="Bild der Ladepunkt">
                        <mat-icon class="evse-health-icon-icon" svgIcon="{{getHealthIcon(evse)}}"></mat-icon>
                    </div>
                    <div class="flex-grow-1">
                        <div class="center" *ngIf="evse">
                            <mat-icon class="iblack mat-icon-evse" inline="true"
                                      [svgIcon]="evse.plugState.icon"></mat-icon>
                            <br>
                            {{evse.plugState.title}}
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <div class="center">
                            <app-radial-bar [value]="power" [label]="'Ladeleistung'" [max]="maxPower" [unit]="'kW'" [fractionDigits]="1"></app-radial-bar>
                        </div>
                    </div>
                    <div class="flex-grow-1">
                        <div class="center">
                            <app-radial-bar [value]="i" [label]="'Ladestrom'" [max]="maxI" [unit]="'A'" [fractionDigits]="1"></app-radial-bar>
                        </div>
                    </div>
                </div>
                <div>
                    <mat-card appearance="outlined" class="graph-container d-relative">
                        <h2 class="m-auto">Verlauf</h2>
                        <div class="actions">
                            <mat-checkbox class="reload-checkbox" #autoReload checked="checked">Automatisch neu laden</mat-checkbox>
                            <app-graph-date-picker *ngIf="graph1" [graph]="graph1"></app-graph-date-picker>
                            <app-graph-date-picker *ngIf="graph2" [graph]="graph2"></app-graph-date-picker>
                            <app-graph-help-icon [options]="['controlZoom', 'leftClickMove', 'shiftLeftClickZoom', 'doubleLeftClickReset']" [vAlign]="'middle'"></app-graph-help-icon>
                        </div>
                        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [(selectedIndex)]="selectedTab" (selectedTabChange)="setSelectedTab($event)">
                            <mat-tab label="Übersicht (Leistung)">
                                <ng-template matTabContent>
                                    <div style="height: 350px">
                                        <app-evse-history-graph #graph2 [evseId]="evse.id" [selectedUnit]="powerUnits.W" [(autoReload)]="autoReload.checked"></app-evse-history-graph>
                                    </div>
                                    <app-background-legend *ngIf="graph2" [matchingGraph]="graph2"></app-background-legend>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="Übersicht (Strom)">
                                <ng-template matTabContent>
                                    <div style="height: 350px">
                                        <app-evse-history-graph #graph1 [evseId]="evse.id" [selectedUnit]="powerUnits.A" [(autoReload)]="autoReload.checked"></app-evse-history-graph>
                                    </div>
                                    <app-background-legend *ngIf="graph1" [matchingGraph]="graph1"></app-background-legend>
                                </ng-template>
                            </mat-tab>
                        </mat-tab-group>
                    </mat-card>
                </div>
                <div>
                    <app-evse-chargings-table class="with-background" [evseId]="evse.id"></app-evse-chargings-table>
                </div>
                <div class="d-flex flex-row align-content-between align-items-stretch gap-3 w-100">
                    <div class="flex-grow-1 with-background w-50">
                        <app-data-table [obj]="evse" [keys]="evseLiveDataTableKeys" headline="Live Daten"></app-data-table>
                    </div>
                    <div class="flex-grow-1 d-flex flex-column gap-3 w-50">
                        <div class="with-background">
                            <app-data-table [obj]="evse" [keys]="evseConfigDataTableKeys" headline="Config Daten"></app-data-table>
                        </div>
                        <app-event-log-table class="with-background" [loadDataCb]="loadHistoryCb"></app-event-log-table>
                        <app-evse-health-status-table class="with-background" [loadHistoryCb]="evseHealthStatusHistory" *ngIf="hasEvseStatisticViewPermission"></app-evse-health-status-table>
                        <app-note-table class="with-background" [source]="evse" [sourceType]="NoteRelatedObject.Evse"></app-note-table>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
}
<ng-template #loading>
    Loading...
</ng-template>
