import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiHandler} from 'src/app/services/api-handlers/api-handler';
import {FleetService} from 'src/app/modules/vehicle/service/fleet.service';
import {AuthService} from "../../shared/guards/auth.service";
import {localStorageGet, localStorageSave} from "../../shared/helper/typed-local-storage";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-default',
    templateUrl: './default.component.html',
    styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
    public isDev?: boolean;
    public muteDevHint = false;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly fleetService: FleetService,
        private readonly authService: AuthService,
        private readonly toastr: ToastrService
        ) {
    }

    ngOnInit(): void {
        this.isDev = this.authService.isDeveloper();
        this.muteDevHint = localStorageGet("MUTE_DEV_HINT", "false") === "true"
        this.activatedRoute.queryParams.subscribe(params => {
            if (params['cid']) {
                ApiHandler.customerId = +params['cid'];
            }
            if (params['fid']) {
                const idFromUrl = +params['fid'];
                //Check if fleet id exists
                const fleetSubject = this.fleetService.get(idFromUrl, false);
                const sub1 = fleetSubject.subscribe(fleet => {
                    if(fleet) {
                        this.fleetService.setSelectedFleet(idFromUrl);
                        sub1.unsubscribe();
                        sub2.unsubscribe();
                    }
                });
                const sub2 = fleetSubject.errorChannel.subscribe(err => {
                    if(err) {
                        if(err?.type === "NOT_FOUND") {
                            this.toastr.warning("Keine Flotte mit ID " + idFromUrl + " gefunden.")
                        }
                        sub1.unsubscribe();
                        sub2.unsubscribe();
                    }
                });
            }
        })
    }

    public updateMuteSetting(): void {
        localStorageSave("MUTE_DEV_HINT", this.muteDevHint ? "true" : "false")
    }
}
