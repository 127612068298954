@if(show404) {
    <app-404 msg="RFID Karte nicht gefunden"></app-404>
} @else {
    <mat-icon svgIcon=""></mat-icon>
    <div *ngIf="(rfid | async) as rfid; else loading" class="d-flex flex-column align-items-stretch gap-3">
        <app-boxes [boxes]="getBoxes(rfid)"></app-boxes>
        <app-note-table class="with-background" [source]="rfid" [sourceType]="NoteRelatedObject.Rfid"></app-note-table>
    </div>
}
<ng-template #loading>
    <mat-spinner></mat-spinner>
    Loading...
</ng-template>
