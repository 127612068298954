import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from '../../../services/api-handlers/notification.service';
import {Subscription} from 'rxjs';
import {NotificationEntry} from '@io-elon-common/frontend-api';
import {FleetService} from '../../../modules/vehicle/service/fleet.service';

@Component({
  selector: 'app-powersupply-fuse-alert',
  templateUrl: './powersupply-fuse-alert.component.html',
  styleUrl: './powersupply-fuse-alert.component.scss'
})
export class PowersupplyFuseAlertComponent implements OnInit, OnDestroy {
    public notification: NotificationEntry | undefined;
    private notificationSubscription !: Subscription;
    private selectedFleetSubscription!: Subscription;
    private fleetId!: number;

    constructor(private readonly notificationService: NotificationService,
                private readonly fleetService: FleetService) {

    }

    ngOnInit(): void {
        this.selectedFleetSubscription = this.fleetService.selectedFleet.subscribe(id => {
            if (id !== undefined) {
                this.fleetId = id;
            }
        });

        this.notificationSubscription = this.notificationService.getActiveNotifications().subscribe(notifications => {
            this.notification = notifications?.activeNotifications.find(notification => notification.key === "fuseload.alert" && notification.fleet === this.fleetId);
        });
    }

    ngOnDestroy(): void {
        this.notificationSubscription.unsubscribe();
        this.selectedFleetSubscription.unsubscribe();
    }

    public close(): void {
        if (this.notification) {
            this.notificationService.ackNotification(this.notification);
        }
    }
}
