import {Component, Input} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-404',
  standalone: true,
    imports: [
        NgOptimizedImage
    ],
  templateUrl: './404.component.html',
  styleUrl: './404.component.scss'
})
export class _404Component {

    @Input()
    public msg: string = "Nicht gefunden"
}
