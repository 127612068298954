import {Component, Input, OnInit} from '@angular/core';
import {MeterService} from "../../service/meter.service";
import {AuthService} from "../../../../shared/guards/auth.service";
import {
    ExecuteMeterAddOrUpdate,
    MeterFormularLiveValues,
    Meter,
    MeterFormularOptions
} from "@io-elon-common/frontend-api";
import {deepEqual} from "../../../../shared/helper/util-functions";
import {BehaviorSubject} from "rxjs";
import {DialogType} from "../../../../shared/components/help-box/dialogType";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {IEditForm} from "../../../../shared/components/dialogs/edit-dialog/edit-dialog.component";

const DEFAULT_FORMULAR_SETTINGS: MeterFormularOptions = {
    closeErrorEvse: true,
    closeNonPluggedEvse: true,
    closeNonAdjustedEvse: false
};

@Component({
  selector: 'app-edit-formula-meter-dialog',
  templateUrl: './edit-formula-meter-dialog.component.html',
  styleUrl: './edit-formula-meter-dialog.component.scss'
})
export class EditFormulaMeterDialogComponent implements IEditForm<ExecuteMeterAddOrUpdate>, OnInit {
    protected readonly DialogType = DialogType;

    @Input()
    public meterId?: number
    @Input()
    public data!: ExecuteMeterAddOrUpdate
    public readonly isDev: boolean;
    public showExpertView = false;
    public liveValues?: BehaviorSubject<MeterFormularLiveValues | undefined>
    public meters!: BehaviorSubject<Meter[] | undefined>;

    public name: FormControl<string | null> = new FormControl(null, Validators.required);
    public formula: FormControl<string | null | undefined> = new FormControl(null);
    public closeErrorEvse: FormControl<boolean | null | undefined> = new FormControl(null);
    public closeNonPluggedEvse: FormControl<boolean | null | undefined> = new FormControl(null);
    public closeNonAdjustedEvse: FormControl<boolean | null | undefined> = new FormControl(null);
    public formGroup = new FormGroup({
        name: this.name,
        formula: this.formula,
        closeErrorEvse: this.closeErrorEvse,
        closeNonPluggedEvse: this.closeNonPluggedEvse,
        closeNonAdjustedEvse: this.closeNonAdjustedEvse
    });

    public constructor(
        private readonly meterService: MeterService,
        authService: AuthService,
    ) {
        this.isDev = authService.isDeveloper();
    }

    ngOnInit(): void {
        if(this.meterId) {
            this.liveValues = this.meterService.getFormulaLiveData(this.meterId, false);
        }
        if(!this.data.formulaConfig) {
            this.data.formulaConfig = {...DEFAULT_FORMULAR_SETTINGS}
        }

        this.name.setValue(this.data.name);
        this.formula.setValue(this.data.formula);
        this.closeErrorEvse.setValue(this.data.formulaConfig.closeErrorEvse || DEFAULT_FORMULAR_SETTINGS.closeErrorEvse);
        this.closeNonPluggedEvse.setValue(this.data.formulaConfig.closeNonPluggedEvse || DEFAULT_FORMULAR_SETTINGS.closeNonPluggedEvse);
        this.closeNonAdjustedEvse.setValue(this.data.formulaConfig.closeNonAdjustedEvse || DEFAULT_FORMULAR_SETTINGS.closeNonAdjustedEvse);

        this.name.valueChanges.subscribe((() => this.data.name = this.name.getRawValue() as string));
        this.formula.valueChanges.subscribe(() => this.data.formula = this.formula.getRawValue() as string | undefined);
        this.closeErrorEvse.valueChanges.subscribe(() => this.data.formulaConfig!.closeErrorEvse = this.closeErrorEvse.getRawValue() as boolean);
        this.closeNonPluggedEvse.valueChanges.subscribe(() => this.data.formulaConfig!.closeNonPluggedEvse = this.closeNonPluggedEvse.getRawValue() as boolean);
        this.closeNonAdjustedEvse.valueChanges.subscribe(() => this.data.formulaConfig!.closeNonAdjustedEvse = this.closeNonAdjustedEvse.getRawValue() as boolean);
    }

    get settingsModified(): boolean {
        return !deepEqual(DEFAULT_FORMULAR_SETTINGS, this.data.formulaConfig);
    }

    validate(): string[] | Promise<string[]> {
        return this.formGroup.valid ? [] : ["Validierungsfehler"];
    }
}
