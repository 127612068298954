<ng-container *ngIf="flexInfo | async as flexInfo; else loading">
    <h2>Übersicht</h2>
    <p>
        Handel mit Flexibilität für diese Flotte <b>{{ flexInfo.enabled ? "Aktiviert" : "Abgeschaltet"}}</b>
    </p>
    <p>
        Aktueller Status: <b>
        @switch (flexInfo.state) {
            @case (StateEnum.Disabled) { Deaktiviert }
            @case (StateEnum.ExecutePlan) { Plan wird umgesetzt }
            @case (StateEnum.MfaBooked) { Flexibilität wurde gebucht }
            @case (StateEnum.MfaOpen) { Flexibilität wird angeboten }
            @case (StateEnum.MfaScheduled) { Flexibilität wurde gebucht und geplant }
            @case (StateEnum.MissingConfig) { In IO-ELON wurde keine Konfiguration für den Flexibilität Handel hinterlegt }
            @case (StateEnum.MissingDerSystem) { In NEMO.spot wurde kein DER-System angelegt. <a (click)="showPopup(createDerSystemInputs)" class="clickable">Anlegen</a> }
            @case (StateEnum.NoMfa) { Im Moment wird keine Flexibilität angeboten }
            @default {
                Unbekannter Status: {{flexInfo.state}}
            }
        }

        </b>
        <br>
        {{flexInfo.info}}
    </p>



    <ng-template #createDerSystemInputs>
        <div class="m-3">
            <h2>DER System anlegen</h2>
            <app-help-box [dialogType]="DialogType.WARNING">
                Es werden die GPS Koordinaten verwendet, die in der Basis hinterlegt sind. Bitte prüfe dass
                diese korrekt sind, da die Angaben in NEMO.spot später nicht mehr korrigiert werden können.
            </app-help-box>
            <mat-form-field class="w-100 transparent" appearance="fill">
                <mat-label>Strasse</mat-label>
                <input type="text" matInput [(ngModel)]="createDerSystemArgs.street">
            </mat-form-field>
            <mat-form-field class="w-100 transparent" appearance="fill">
                <mat-label>Postleitzahl</mat-label>
                <input type="text" matInput [(ngModel)]="createDerSystemArgs.zip">
            </mat-form-field>
            <mat-form-field class="w-100 transparent" appearance="fill">
                <mat-label>Ort</mat-label>
                <input type="text" matInput [(ngModel)]="createDerSystemArgs.city">
            </mat-form-field>
            <mat-form-field class="w-100 transparent" appearance="fill">
                <mat-label>Land</mat-label>
                <input type="text" matInput [(ngModel)]="createDerSystemArgs.country">
            </mat-form-field>
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>ZoneId</mat-label>
                <mat-select [(ngModel)]="createDerSystemArgs.zoneId">
                    <mat-option *ngFor="let z of flexInfo.zoneIds || []" [value]="z">{{z}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="small">Wenn bei NEMO.spot neue ZoneIds hinterlegt werden, kann es bis zu 10 Minuten dauern, bis diese hier auftauchen</div>
            <div class="mt-3 d-flex justify-content-end">
                <button mat-raised-button color="primary" (click)="createDerSystem()">Anlegen</button>
            </div>
        </div>
    </ng-template>
</ng-container>


<ng-template #loading>
Daten werden geladen...
</ng-template>

