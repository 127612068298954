@if(show404) {
    <app-404 msg="Smartmeter nicht gefunden"></app-404>
} @else {
    <div *ngIf="(meter | async) as meter; else loading" class="d-flex flex-column align-items-stretch gap-3">
        <div *ngIf='meterInfo?.type === "API"' style="min-height: 25px">
            <app-help-box-icon [iconType]="DialogType.HELP" class="help-icon" #helpIcon></app-help-box-icon>
            <app-help-box [dialogType]="DialogType.HELP" [helpIcon]="helpIcon" key="SMARTMETER_API_POPUP">
                <p>
                    Dieser Smart-Meter wird von IO-ELON nicht automatisiert abgefragt. Die Daten können statt dessen
                    über eine API bereitgestellt werden. Siehe public/smartmeter/v1 in der <a href="/swaggerDoc">API-Dokumentation</a> für Details.
                </p>
            </app-help-box>
        </div>
        <div class="d-flex gap-2">
            <img [src]="'/img/smartMeter/'+meter.image" alt="Bild des Energiezählers" class="flex-grow-0" >
            <div class="boxes flex-grow-1">
                @for (box of boxes; track box.description) {
                    <app-boxes [boxes]="[box]"></app-boxes>
                    <div *ngIf="!$last" class="spacer"></div>
                }
            </div>
            @if (meter.type === "FormulaMeter") {
                <mat-card class="flex-grow-1">
                    <mat-card-title class="c-blue-1">Formel</mat-card-title>
                    <mat-card-content class="p-0 mt-1">
                        <app-formula-display [meter]="meter" [showAmps]="showAmps" [showPwr]="showPwr"></app-formula-display>
                    </mat-card-content>
                    <div class="flex-grow-1"></div>
                    <mat-card-footer>
                        <div class="d-flex justify-content-end">
                            <mat-checkbox [ngModel]="showAmps" (change)="showAmps = $event.checked">Strom Anzeigen</mat-checkbox>
                            <mat-checkbox [ngModel]="showPwr" (change)="showPwr = $event.checked">Leistung Anzeigen</mat-checkbox>
                        </div>
                    </mat-card-footer>
                </mat-card>
            }
        </div>
        <div style="background: white" class="p-2">
            <div class="m-0 d-flex justify-content-end">
                <app-graph-help-icon [options]="['controlZoom', 'leftClickMove', 'shiftLeftClickZoom', 'doubleLeftClickReset']" [vAlign]="'middle'"></app-graph-help-icon>
            </div>
            <app-meter-history-graph [meterId]="meter.id"></app-meter-history-graph>
        </div>
        <div style="background: white" class="note-table">
            <app-note-table [source]="meter" [sourceType]="NoteRelatedObject.Meter"></app-note-table>
        </div>
        <div class="buttons d-flex flex-row justify-content-end gap-3">
            <button *ngIf='meterInfo?.type === "API" || authService.isDeveloper()' mat-raised-button color="primary" (click)="handleApiDataV1Click()">Daten per API bereitstellen</button>
        </div>
    </div>
}

<ng-template #loading>
    <mat-spinner></mat-spinner>
    Loading...
</ng-template>
