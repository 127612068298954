import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Box, NoteRelatedObject, Rfid} from '@io-elon-common/frontend-api';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {RfidService} from '../../service/rfid.service';
import {ApiErrors, BehaviorSubjectWithErrorChannel} from "../../../../services/api-handlers/cacheManager";

@Component({
  selector: 'app-rfid-details-view',
  templateUrl: './rfid-details-view.component.html',
  styleUrl: './rfid-details-view.component.scss'
})
export class RfidDetailsViewComponent implements OnInit, OnDestroy{
    public rfid!: BehaviorSubjectWithErrorChannel<Rfid | undefined, ApiErrors>
    private routerEventSubscription!: Subscription;
    private errorSubscription?: Subscription;
    public show404 = false;
    protected readonly NoteRelatedObject = NoteRelatedObject;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly rfidService: RfidService,
        private readonly router: Router
    ) {
    }

    public ngOnInit(): void {
        this.routerEventSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.afterNavigation();
            }
        });
        this.afterNavigation();
    }

    private afterNavigation() {
        this.show404 = false;
        const id = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);
        if (id) {
            this.rfid = this.rfidService.get(id, false);
            this.errorSubscription?.unsubscribe();
            this.errorSubscription = this.rfid.errorChannel.subscribe(err => {
                this.show404 = err?.type === "NOT_FOUND";
            });
        }
    }

    public getBoxes(rfid: Rfid){
        if(rfid !== undefined) {
            const boxes: (Box & {link?: string})[] = [
                {value: rfid.name, description: 'RFID Karte', icon: 'sim_card'},
                {value: rfid.vehicle?.name ?? "---", description: 'Fahrzeug', icon: 'car'},
                {value: rfid.token ?? "--", description: 'Token', icon: 'id'},
            ]
            return boxes;
        }
    }

    public ngOnDestroy(): void {
        this.routerEventSubscription.unsubscribe();
        this.errorSubscription?.unsubscribe();
    }
}
