<div class="d-flex flex-column gap-3">
    <div class="d-flex flex-row gap-3">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput [formControl]="name">
            <mat-error>{{name | validationErrorFormatter}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Angeschlossen an:</mat-label>
            <mat-select [formControl]="powerSupplyId">
                <mat-option *ngFor="let p of possiblePowerSupplies" [value]="p.id">{{p.name}} </mat-option>
            </mat-select>
            <mat-error *ngIf="powerSupplyId.invalid">{{powerSupplyId | validationErrorFormatter}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Typ:</mat-label>
            <mat-select [formControl]="type">
                <mat-option [value] ="DynamicLoadType.MeterBasedDynamicLoad">Meter</mat-option>
                <mat-option [value] ="DynamicLoadType.UserDefinedLoad">Nutzer definiert</mat-option>
            </mat-select>
            <mat-error>{{type | validationErrorFormatter}}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="data.type === DynamicLoadType.UserDefinedLoad" appearance="fill">
            <mat-label>Wiederholungsintervall:</mat-label>
            <mat-select [formControl]="repeatInterval" (valueChange)="updateGraphLen()">
                <mat-option [value] ="RepeatIntervalEnum.Day">Tag</mat-option>
                <mat-option [value] ="RepeatIntervalEnum.Week">Woche</mat-option>
            </mat-select>
            <mat-error>{{repeatInterval | validationErrorFormatter}}</mat-error>
        </mat-form-field>
    </div>
    <div style="max-width:650px" class="d-flex flex-row flex-wrap gap-3">
        <mat-form-field *ngIf="data.type === DynamicLoadType.MeterBasedDynamicLoad && (meters | async) as meters" appearance="fill">
            <mat-label>Messgerät:</mat-label>
            <mat-select [formControl]="meterId">
                <mat-option *ngFor="let m of meters" [value]="m.id">{{m.name}} </mat-option>
            </mat-select>
            <mat-error>{{meterId | validationErrorFormatter}}</mat-error>
        </mat-form-field>
        @if(data.type !== DynamicLoadType.UserDefinedLoad) {
            <mat-form-field appearance="fill">
                <mat-label>Glättungszeitraum:</mat-label>
                <input matInput type="number" min="0" [formControl]="slidingMaxMinutes">
                <span matSuffix class="px-2">Minuten&nbsp;</span>
                <mat-error>{{slidingMaxMinutes | validationErrorFormatter}}</mat-error>
            </mat-form-field>
            <app-help-box-icon #iconSlidingMax [iconType]="DialogType.HELP"></app-help-box-icon>
            <app-help-box [dialogType]="DialogType.HELP" [closedByDefault]="true" [closable]="true"
                          [helpIcon]="iconSlidingMax" [key]="'LOAD_SLIDING_MAX'">
                <p>
                    <strong>Glättungszeitraum:</strong>
                    Mit dem Glättungszeitraum kann eingestellt werden, wie die dynamische Last nachbearbeitet werden soll.
                </p>
                <p>
                    <strong>Problem:</strong>
                    Wenn ein Gebäude oder eine sonstige dynamische Last Schwankungen unterliegt, kann dies sich auf das
                    Ladeverhalten der Fahrzeuge übertragen. Es ist z.B. möglich, dass ein Kühlaggregat alle 10 Minuten für
                    eine Minute eingeschaltet wird. Um den Anschluss nicht zu überlasten, muss dann die Ladeleistung der
                    Fahrzeuge reduziert werden. Da dieser Vorgang sich oft wiederholt, kann es zu ungewünschten Starts
                    und Stopps von Ladevorgängen führen.
                </p>
                <p>
                    <strong>Lösung:</strong>
                    Um die Fahrzeuge zu schonen, kann eingestellt werden, dass für das Gebäude die maximale gemessene
                    Leistung der letzten x Minuten reserviert werden soll. Wenn also z.B. 15 Minuten ausgewählt ist, wird
                    die Energie für das Kühlaggregat ständig reserviert (da es sich alle 10 Minuten einschalten). So werden Starts und Stopps von Ladevorgängen
                    verhindert.
                </p>
                <p>
                    <strong>Hinweis:</strong>
                    Diese Einstellung ist bei Nutzer definierten Lasten nicht verfügbar, da Sie dort nicht sinnvoll ist.
                    Für Lasten des Typs Meter kann maximal {{ maxMeterSlidingMaxTimeMinutes }} Minuten
                    konfiguriert werden. Wenn das nicht ausreichend ist, können Sie sich an den Support wenden oder
                    alternativ eine Formel benutzen. Dort sind bis zu 24h möglich.
                </p>
            </app-help-box>
        }
    </div>
    @if(data.type === DynamicLoadType.UserDefinedLoad) {
        <div *ngIf="data.repeatInterval === RepeatIntervalEnum.Week">
            <mat-tab-group>
                <mat-tab label="Montag">
                    <ng-template matTabContent>
                        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="0" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Dienstag">
                    <ng-template matTabContent>
                        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="1" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Mittwoch">
                    <ng-template matTabContent>
                        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="2" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Donnerstag">
                    <ng-template matTabContent>
                        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="3" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Freitag">
                    <ng-template matTabContent>
                        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="4" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Samstag">
                    <ng-template matTabContent>
                        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="5" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Sontag">
                    <ng-template matTabContent>
                        <app-user-defined-load-graph *ngIf="data.type === 'UserDefinedLoad'" dayOffset="6" dayCount="7" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div *ngIf="data.repeatInterval === RepeatIntervalEnum.Day">
            <app-user-defined-load-graph dayOffset="0" dayCount="1" [graphPoints]="data.graphPointList"></app-user-defined-load-graph>
            <mat-hint>Zu hohe Annahmen können tatsächliches Einsparpotenzial verringern. Zu niedrige Annahmen können zu einer Überlastung des Anschlusses führen.</mat-hint>
        </div>
        <div>
            <mat-hint>Zeitzone: {{data.zone}}</mat-hint>
        </div>
    }
    @if (data.type === DynamicLoadType.MeterBasedDynamicLoad) {
        <hr>
            <app-help-box [closable]="false" [dialogType]="DialogType.INFO" style="max-width:650px">
                <p>
                    Bei einem Ausfall des Zählers wird durch den Fallback vor Überlast geschützt.
                    Es wird bei einem Ausfall / Störung des verlinkten Zähler auf den hier
                    definierten Strom zurückgegriffen, um vor Sicherungsausfällen und hohen Peaks zu schützen.
                </p>
            </app-help-box>

        <div class="d-flex flex-row gap-2">
            <mat-form-field appearance="fill">
                <mat-label>Fallback Strom I1</mat-label>
                <input type="number" matInput [formControl]="fallbackI1">
                <mat-error>{{fallbackI1 | validationErrorFormatter}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Fallback Strom I2</mat-label>
                <input type="number" matInput [formControl]="fallbackI2">
                <mat-checkbox matSuffix class="position-absolute" style="right: 0; top: -10px" disabled="" [checked]="fallbackI1.getRawValue() == fallbackI2.getRawValue()">Sync</mat-checkbox>
                <mat-error>{{fallbackI2 | validationErrorFormatter}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Fallback Strom I3</mat-label>
                <input type="number" matInput [formControl]="fallbackI3">
                <mat-checkbox matSuffix class="position-absolute" style="right: 0; top: -10px" disabled="" [checked]="fallbackI1.getRawValue() == fallbackI3.getRawValue()">Sync</mat-checkbox>
                <mat-error>{{fallbackI3 | validationErrorFormatter}}</mat-error>
            </mat-form-field>
        </div>
    }
</div>
