<div *ngIf="(accounts | async) as accounts; else loading">
    <app-webfleet-account-table [accounts]="accounts"></app-webfleet-account-table>
    <div class="d-flex justify-content-end gap-3">
        <div class="buttons d-flex flex-row justify-content-end gap-3">
            @if(canSync) {<a href = "https://webfleet.io-elon.de"><button mat-raised-button color="primary"><mat-icon>bug_report</mat-icon>µService Frontend</button></a>}
            <button [disabled]="!canSync" mat-raised-button color="primary" (click)="synchronizeAccounts()"><mat-icon>bug_report</mat-icon>Accounts Synchronisieren</button>
            <button [disabled]="!canAdd" mat-raised-button color="primary" (click)="createAccount()">Account Hinzufügen</button>
        </div>
    </div>
</div>
<ng-template #loading>
    <div class="loading">Loading...</div>
</ng-template>
