@if ((liveData | async); as liveData) {
    <table [class.lines]="showPwr || showAmps" class="w-auto">
        <colgroup>
            <col class="sum">
            <col class="operator">
            <col class="name">
            @if(showAmps) {
                <col class="amps amps1">
                <col class="amps amps2">
                <col class="amps amps3">
            }
            @if(showPwr) {
                <col class="pwr pwr1">
                <col class="pwr pwr2">
                <col class="pwr pwr3">
                <col class="pwr pwrSum">
            }
        </colgroup>
        @if (showAmps || showPwr) {
            <tr>
                <td colspan="3"></td>
                @if(showAmps) {
                    <th class="amps">I1</th>
                    <th class="amps">I2</th>
                    <th class="amps">I3</th>
                }
                @if(showPwr) {
                    <th class="pwr">P1</th>
                    <th class="pwr">P2</th>
                    <th class="pwr">P3</th>
                    <th class="pwr">Summe</th>
                }
            </tr>
        }
        @for (term of formula.terms; track $index) {
            <tr>
                @if ($first) {
                    <th class="result">
                        {{meter.name}} =
                    </th>
                } @else {
                    <td></td>
                }

                @switch (term.operation) {
                    @case (Operation.ADD) { <td class="operator add">+</td> }
                    @case (Operation.SUBTRACT) { <td class="operator subtract">-</td> }
                }

                <td class="pe-2">
                    @switch (term.type) {
                        @case (TermType.METER_TERM) { <ng-container *ngTemplateOutlet="meterTerm; context: {term: term}"></ng-container>  }
                        @case (TermType.EVSE_TERM) { <ng-container *ngTemplateOutlet="evseTerm; context: {term: term}"></ng-container> }
                        @case (TermType.LOAD_TERM) { <ng-container *ngTemplateOutlet="loadTerm; context: {term: term}"></ng-container> }
                        @case (TermType.CONSTANT_TERM) { <ng-container *ngTemplateOutlet="constantTerm; context: {term: term}"></ng-container> }
                    }
                </td>

                @if (showAmps) {
                    <td class="amps">{{i1(term, liveData) | eng:"A"}}</td>
                    <td class="amps">{{i2(term, liveData) | eng:"A"}}</td>
                    <td class="amps">{{i3(term, liveData) | eng:"A"}}</td>
                }
                @if (showPwr) {
                    <td class="pwr">{{p1(term, liveData) | eng:"W"}}</td>
                    <td class="pwr">{{p2(term, liveData) | eng:"W"}}</td>
                    <td class="pwr">{{p3(term, liveData) | eng:"W"}}</td>
                    <td class="pwr">{{p1(term, liveData) + p2(term, liveData) + p3(term, liveData) | eng:"W"}}</td>
                }
            </tr>
        }
        @if(showAmps || showPwr) {
            <tr>
                <td></td>
                <th colspan="2">Summe:</th>
                @if(showAmps) {
                    <th class="amps">
                        {{liveData.result.i1 | eng:"A"}}<br>
                        <ng-container *ngTemplateOutlet="windowWithHelp; context: {val: formatWindow(liveData.result.i1Window, 'A')}"/>
                    </th>
                    <th class="amps">
                        {{liveData.result.i2 | eng:"A"}}<br>
                        <ng-container *ngTemplateOutlet="windowWithHelp; context: {val: formatWindow(liveData.result.i2Window, 'A')}"/>
                    </th>
                    <th class="amps">
                        {{liveData.result.i3 | eng:"A"}}<br>
                        <ng-container *ngTemplateOutlet="windowWithHelp; context: {val: formatWindow(liveData.result.i3Window, 'A')}"/>
                    </th>
                }
                @if(showPwr) {
                    <th class="pwr">
                        {{liveData.result.p1 | eng:"W"}}<br>
                        <ng-container *ngTemplateOutlet="windowWithHelp; context: {val: formatWindow(liveData.result.p1Window, 'W')}"/>
                    </th>
                    <th class="pwr">
                        {{liveData.result.p2 | eng:"W"}}<br>
                        <ng-container *ngTemplateOutlet="windowWithHelp; context: {val: formatWindow(liveData.result.p2Window, 'W')}"/>
                    </th>
                    <th class="pwr">
                        {{liveData.result.p3 | eng:"W"}}<br>
                        <ng-container *ngTemplateOutlet="windowWithHelp; context: {val: formatWindow(liveData.result.p3Window, 'W')}"/>
                    </th>
                    <th class="pwr">
                        {{(liveData.result.p1 + liveData.result.p2 + liveData.result.p3) | eng:"W"}}<br>
                        <ng-container *ngTemplateOutlet="windowWithHelp; context: {val: formatWindow(liveData.result.p1Window + liveData.result.p2Window + liveData.result.p3Window, 'W')}"/>
                    </th>
                }
            </tr>
        }

    </table>

    <ng-template #windowWithHelp let-val="val">
        <span class="help-cursor" matTooltip="Bei der Berechnung von Formeln wird ein Verfahren eingesetzt, bei dem Änderungen eines Messwertes nicht sofort wirksam werden, sondern als Abweichung abgelegt werden. Durch dieses Verfahren ist es möglich, auch Messwerte mit unterschiedlichen Abtastraten zu verrechnen.">
            {{val}}
        </span>
    </ng-template>

    <ng-template #meterTerm let-term="term">
        Zähler: <app-meter-link [meterId]="term.id" [showAlerts]="false"></app-meter-link>
    </ng-template>
    <ng-template #evseTerm let-term="term">
        Ladepunkt: <app-evse-link [evseId]="term.id" [showAlerts]="false"></app-evse-link>
    </ng-template>
    <ng-template #loadTerm let-term="term">
        Last: {{loadName(term.id)}}
    </ng-template>
    <ng-template #constantTerm let-term="term">
        {{term.p1 | eng:"W"}}, {{term.p2 | eng:"W"}}, {{term.p3 | eng:"W"}}
    </ng-template>
}
