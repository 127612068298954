import {Component, Input} from '@angular/core';
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IEditForm} from "../../../shared/components/dialogs/edit-dialog/edit-dialog.component";
import {WebfleetLogin} from "@io-elon-common/frontend-api";
import {MatCheckbox} from "@angular/material/checkbox";

@Component({
  selector: 'app-login-webfleet-account-dialog',
  standalone: true,
    imports: [
        MatFormField,
        MatInput,
        MatLabel,
        ReactiveFormsModule,
        FormsModule,
        MatCheckbox
    ],
  templateUrl: './login-webfleet-account-dialog.component.html',
  styleUrl: './login-webfleet-account-dialog.component.scss'
})
export class LoginWebfleetAccountDialogComponent implements IEditForm<WebfleetLogin> {

    @Input()
    public data!: WebfleetLogin;

    validate(): string[] | Promise<string[]> {
        return [];
    }

}
