<h2 class="center">{{ headline }}</h2>
<table mat-table [dataSource]="dataSource" class="full-width-table" matSort>

    <!-- Key Column -->
    <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef class="col-icon">Feld</th>
        <td mat-cell *matCellDef="let element">
            {{ element.key }}<sup *ngIf="element.reference">{{ element.reference }}</sup>
        </td>
    </ng-container>

    <!-- Value Column -->
    <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef class="col-value">Wert</th>
        <td mat-cell *matCellDef="let element" class="col-value">
            <span [matTooltip]="element.value.age" [matTooltipPosition]="'right'"
                  *ngIf="isSimple(element.value); else complex">{{ element.value }}</span>
            <ng-template #complex>
                <span [matTooltip]="element.value.age" [matTooltipPosition]="'right'">{{ element.value.value }}</span>
                <mat-icon *ngIf="element.value.showAgeIcon" [matTooltip]="element.value.age">cloud_off</mat-icon>
            </ng-template>
        </td>
    </ng-container>

    <!-- Source Column -->
    <ng-container matColumnDef="source">
        <th mat-header-cell *matHeaderCellDef class="col-icon"></th>
        <td mat-cell *matCellDef="let element" class="col-source">
            @switch (element.value.source) {
                @case (DataValueSource.Service) {
                    <i class="fa-solid fa-lg fa-microchip" matTooltip="Wert von Service"></i>
                }
                @case (DataValueSource.Vehicle) {
                    <i class="fa-solid fa-lg fa-car-side" matTooltip="Wert von Fahrzeug"></i>
                }
                @case (DataValueSource.MappedFromVehicle) {
                    <i class="fa-solid fa-lg fa-car-side" matTooltip="Wert von Fahrzeug"></i>
                }
                @case (DataValueSource.Evse) {
                    <i class="fas fa-lg fa-charging-station" matTooltip="Wert von Ladepunkt"></i>
                }
                @case (DataValueSource.MappedFromEvse) {
                    <i class="fas fa-lg fa-charging-station" matTooltip="Wert von Ladepunkt"></i>
                }
                @case (DataValueSource.Api) {
                    <mat-icon style="font-size: 20px;"
                              matTooltip="Wert von IO-Elon-API">developer_mode</mat-icon>
                }
                @case (DataValueSource.Calculated) {
                    <i class="fa-solid fa-lg fa-calculator" matTooltip="Wert berechnet"></i>
                }
                @case (DataValueSource.PluggedWindow) {
                    <i class="fa-solid fa-lg fa-plug" matTooltip="Wert von Plugged Window"></i>
                }
                @case (DataValueSource.Other) {
                    <i class="fa-solid fa-lg fa-question" matTooltip="Wert von Andere"></i>
                }
            }
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"
        (click)="$event.stopPropagation()">
    </tr>

</table>
<span class="mat-caption" *ngIf="captions.length>0">
    <ng-container *ngFor="let caption of captions; let i = index;">
        {{ i + 1 }}: {{ caption.text }}
        <ng-container *ngIf="caption.link"><a [href]="caption.link">{{ caption.link }}</a></ng-container><br>
    </ng-container>
</span>
