import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Box, Dongle, NoteRelatedObject} from '@io-elon-common/frontend-api';
import {DongleService} from '../../service/dongle.service';
import {DatePipe} from '@angular/common';
import {ApiErrors, BehaviorSubjectWithErrorChannel} from "../../../../services/api-handlers/cacheManager";

@Component({
  selector: 'app-dongle-details-view',
  templateUrl: './dongle-details-view.component.html',
  styleUrl: './dongle-details-view.component.scss'
})
export class DongleDetailsViewComponent implements OnInit, OnDestroy{
    public dongle!: BehaviorSubjectWithErrorChannel<Dongle | undefined, ApiErrors>
    private routerEventSubscription!: Subscription;
    private errorSubscription?: Subscription;
    public readonly NoteRelatedObject = NoteRelatedObject;
    public show404 = false;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly dongleService: DongleService,
        private readonly router: Router,
        private readonly datePipe: DatePipe
    ) {
    }

    public ngOnInit(): void {
        this.routerEventSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.afterNavigation();
            }
        });
        this.afterNavigation();
    }

    private afterNavigation() {
        const id = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);
        if (id) {
            this.dongle = this.dongleService.get(id, false);
            this.show404 = false;
            this.errorSubscription?.unsubscribe();
            this.errorSubscription = this.dongle.errorChannel.subscribe(err => {
                this.show404 = err?.type === "NOT_FOUND";
            })
        } else {
            this.show404 = true;
        }
    }

    public ngOnDestroy(): void {
        this.routerEventSubscription.unsubscribe();
        this.errorSubscription?.unsubscribe();
    }

    public getBoxes(dongle: Dongle): Array<Box & {link?: string}> | undefined {
        if(dongle !== undefined) {
           const boxes: (Box & {link?: string})[] = [
                {value: dongle.name, description: 'Dongle', icon: 'plug'},
                {value: dongle.vehicle?.name ?? "---", description: 'Fahrzeug', icon: 'car'},

                {value: dongle.dongleVin ?? "--", description: 'Vin', icon: 'id'},
                {value: this.lastContactToHumanReadable(dongle.lastMsg) ?? "--", description: 'Letzter Kontakt', icon: 'verwaltung'},
            ]
            return boxes;
        }
    }

    public lastContactToHumanReadable(lastContact: number | undefined): string | undefined {
       return lastContact ? (this.datePipe.transform(lastContact) +" - " + this.datePipe.transform(lastContact, "shortTime")) : undefined;
    }

}
