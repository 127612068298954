import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Box, Meter, NoteRelatedObject, SystemInfoMeterType} from '@io-elon-common/frontend-api';
import {MeterService} from 'src/app/modules/meter/service/meter.service';
import {SystemService} from "../../../../services/api-handlers/system.service";
import {AuthService} from "../../../../shared/guards/auth.service";
import {DatePipe} from '@angular/common';
import {DialogType} from '../../../../shared/components/help-box/dialogType';
import {ApiErrors, BehaviorSubjectWithErrorChannel} from "../../../../services/api-handlers/cacheManager";

@Component({
    selector: 'app-meter-details-view',
    templateUrl: './meter-details-view.component.html',
    styleUrls: ['./meter-details-view.component.scss']
})
export class MeterDetailsViewComponent implements OnInit, OnDestroy {
    private meterSubscription!: Subscription;
    private errorSubscription?: Subscription;
    private routerEventSubscription!: Subscription;
    private currentMeterId: number | undefined = undefined;
    private meterTypes: Array<SystemInfoMeterType> = [];
    public readonly NoteRelatedObject = NoteRelatedObject;
    public boxes: Box[] = [];
    public show404 = false;

    public meter!: BehaviorSubjectWithErrorChannel<Meter | undefined, ApiErrors>;
    public meterInfo?: SystemInfoMeterType;
    public readonly DialogType = DialogType;
    showPwr = false;
    showAmps = false;


    public constructor(
        private readonly meterService: MeterService,
        private readonly route: ActivatedRoute,
        private readonly systemService: SystemService,
        public readonly authService: AuthService,
        private readonly datePipe: DatePipe,
        private readonly router: Router
    ) {
    }

    public ngOnInit(): void {
        this.routerEventSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.currentMeterId !== undefined) {
                    const id = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);
                    if (this.currentMeterId !== id) {
                        this.meterSubscription.unsubscribe();
                        this.errorSubscription?.unsubscribe();
                        this.init();
                    }
                }
            }
        });

        this.init();
    }

    private update(meter: Meter): void {
        let lastContact = this.datePipe.transform(new Date(meter.lastMsg), 'dd.MM.yyyy, HH:mm:ss');
        if (meter.lastMsg === 0 || lastContact === null) {
            lastContact = "Unbekannt";
        }

        this.meterInfo = this.systemService.getSystemInfoSync()!.supportedMeters.find(x => x.key === this.meter.value?.type);
        const type = this.meterInfo?.type;

        this.boxes = [{
            description: 'Name',
            icon: 'desktop_mac-black',
            percentage: 33,
            value: meter.name || '---'
        }, {
            description: 'Typ',
            icon: 'settings',
            percentage: 33,
            value: this.meterTypes.find(t => t.key === meter.type)?.name ?? "Unbekannt"
        }];

        if(type !== "Formula") {

            let address = "";
            switch (type) {
                case "API":
                    address = "/api/smartmeter/v1/"+meter.id;
                    break;
                case "Http":
                    address = meter.hostname + ':' + meter.port;
                    break;
                case "Modbus":
                    address = meter.hostname + ':' + meter.port + " - " + meter.modbusId
                    break;
            }

            this.boxes.push({
                description: 'Adresse',
                icon: 'search',
                percentage: 33,
                value: address
            }, {
                description: 'Letzter Kontakt',
                icon: 'clock',
                percentage: 33,
                value: lastContact
            })
        }
    }

    public ngOnDestroy(): void {
        this.routerEventSubscription.unsubscribe();
        this.errorSubscription?.unsubscribe();
        this.meterSubscription.unsubscribe();
    }

    public async handleApiDataV1Click(): Promise<void> {
        let meter = this.meter.value;
        if (meter) {
            await this.meterService.showApiDataV1Dialog(meter.id);
        }
    }

    validate(): string[] {
        return [];
    }

    private async init(): Promise<void> {
        this.currentMeterId = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);
        this.meter = this.meterService.get(this.currentMeterId, false);
        this.meterTypes = (await this.systemService.getSystemInfo()).supportedMeters;
        this.meterSubscription = this.meter.subscribe(meter => {
            if (meter) {
                this.show404 = false;
                this.update(meter);
            }
        });
        this.errorSubscription = this.meter.errorChannel.subscribe(err => {
            this.show404 = err?.type === "NOT_FOUND";
        });

    }
}
