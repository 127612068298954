import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {FleetService} from "../../../vehicle/service/fleet.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {FleetFlexCreateDerSystemArgs, FleetFlexInfo} from "@io-elon-common/frontend-api";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogType} from "../../../../shared/components/help-box/dialogType";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-flex-view',
  standalone: false,
  templateUrl: './flex-view.component.html',
  styleUrl: './flex-view.component.scss'
})
export class FlexViewComponent implements OnInit, OnDestroy {
    public readonly DialogType = DialogType;
    private fleetSubscription?: Subscription;
    private selectedFleetId?: number;
    public createDerSystemArgs: FleetFlexCreateDerSystemArgs = {
        city: "",
        zip: "",
        country: "Germany",
        street: "",
        zoneId: ""
    };

    public flexInfo: BehaviorSubject<FleetFlexInfo | undefined> = new BehaviorSubject<FleetFlexInfo | undefined>(undefined);
    private popup?: MatDialogRef<any>;

    public constructor(
        private readonly fleetService: FleetService,
        private readonly dialog: MatDialog,
        private readonly toastr: ToastrService,
    ) {

    }

    ngOnInit(): void {
        this.fleetSubscription = this.fleetService.selectedFleet.subscribe(f => {
            if(typeof f === "number") {
                this.flexInfo = this.fleetService.getFlexInfo(f);
                this.selectedFleetId = f;
            }
        });
    }

    ngOnDestroy(): void {
        this.fleetSubscription?.unsubscribe();
    }

    public readonly StateEnum = FleetFlexInfo.StateEnum;

    showPopup(createDerSystemInputs: TemplateRef<any>) {
        this.popup = this.dialog.open(createDerSystemInputs);
    }


    private validateDerSystem(): boolean {
        return !!this.createDerSystemArgs.zip &&
            !!this.createDerSystemArgs.city &&
            !!this.createDerSystemArgs.zip &&
            !!this.createDerSystemArgs.country &&
            !!this.createDerSystemArgs.street &&
            !!this.createDerSystemArgs.zoneId;

    }

    async createDerSystem() {
        if(this.selectedFleetId !== undefined) {
            if(!this.validateDerSystem()) {
                this.toastr.warning("Bitte alle Felder ausfüllen")
                return;
            }
            await this.fleetService.createDerSystem(this.selectedFleetId, this.createDerSystemArgs);
            if(this.popup) {
                this.popup.close();
            }
        } else {
            this.toastr.warning("Keine Flotte ausgewählt.")
        }
    }
}
