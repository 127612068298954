import {PhaseRotation} from "@io-elon-common/frontend-api";

export class Formula {
    terms: Term[];

    constructor(terms: Term[]) {
        this.terms = terms;
    }

    public static parse(str: string): Formula {
        const terms = [];
        while(str.length > 0) {
            const parseResult = Term.parseNext(str);
            terms.push(parseResult[0]);
            str = parseResult[1];
        }
        return new Formula(terms);
    }

    public toString(): string {
        return this.terms.map(term => term.toString()).join(" ");
    }
}

export enum Operation {
    ADD = "+",
    SUBTRACT = "-"
}

export enum TermType {
    EVSE_TERM = "E",
    METER_TERM = "M",
    CONSTANT_TERM = "C",
    LOAD_TERM = "L"
}

export abstract class Term {
    operation: Operation;
    type: TermType;

    constructor(operation: Operation, type: TermType) {
        this.operation = operation;
        this.type = type;
    }

    public static parseNext(str: string): [Term, string] {
        const operation: Operation = str.charAt(0) as Operation;
        const type: TermType = str.charAt(2) as TermType;
        str = str.substr(4);

        let result: [Term, string];
        switch (type) {
            case TermType.CONSTANT_TERM:
                result = ConstTerm.parseArgs(operation, str);
                break;
            case TermType.EVSE_TERM:
                result = EvseTerm.parseArgs(operation, str);
                break;
            case TermType.LOAD_TERM:
                result = LoadTerm.parseArgs(operation, str);
                break;
            case TermType.METER_TERM:
                result = MeterTerm.parseArgs(operation, str);
                break;
            default:
                throw new Error("Unknown Term Type: " + str);
        }

        if(result[1].length > 0) {
            result[1] = result[1].substr(1);
        }

        return result;
    }

    public toString(): string {
        return `${this.operation} ${this.type} ${this.formatArgs()}`;
    }

    public abstract formatArgs(): string;
}

export class EvseTerm extends Term {
    id: number;

    constructor(operation: Operation, id: number) {
        super(operation, TermType.EVSE_TERM);
        this.id = id;
    }

    public static parseArgs(operation: Operation, str: string): [Term, string] {
        const argRaw = /^([^ ]+)/.exec(str)
        if(argRaw == null) {
            throw new Error("Unable to parse constant evse.")
        }

        const id = +argRaw[1]

        str = str.substr(argRaw[0].length)
        return [new EvseTerm(operation, id), str];
    }

    public formatArgs(): string {
        return `${this.id}`
    }
}

export class MeterTerm extends Term {
    id: number;
    rotation: PhaseRotation

    constructor(operation: Operation, id: number, rotation: PhaseRotation) {
        super(operation, TermType.METER_TERM);
        this.id = id;
        this.rotation = rotation;
    }

    public static parseArgs(operation: Operation, str: string): [Term, string] {
        const argRaw = /^([^ ]+) ([^ ]+)/.exec(str)
        if(argRaw == null) {
            throw new Error("Unable to parse constant Meter.")
        }

        const id = +argRaw[1];
        const rotation = argRaw[2] as PhaseRotation;

        str = str.substr(argRaw[0].length);
        return [new MeterTerm(operation, id, rotation), str];
    }

    public formatArgs(): string {
        return `${this.id} ${this.rotation}`
    }
}

export class LoadTerm extends Term {
    id: number;
    rotation: PhaseRotation

    constructor(operation: Operation, id: number, rotation: PhaseRotation) {
        super(operation, TermType.LOAD_TERM);
        this.id = id;
        this.rotation = rotation;
    }

    public static parseArgs(operation: Operation, str: string): [Term, string] {
        const argRaw = /^([^ ]+) ([^ ]+)/.exec(str)
        if(argRaw == null) {
            throw new Error("Unable to parse constant Load.")
        }

        const id = +argRaw[1];
        const rotation = argRaw[2] as PhaseRotation;

        str = str.substr(argRaw[0].length);
        return [new LoadTerm(operation, id, rotation), str];
    }

    public formatArgs(): string {
        return `${this.id} ${this.rotation}`
    }
}

export class ConstTerm extends Term {
    p1: number;
    p2: number;
    p3: number;

    constructor(operation: Operation, p1: number, p2: number, p3: number) {
        super(operation, TermType.CONSTANT_TERM);
        this.p1 = p1;
        this.p2 = p2;
        this.p3 = p3;
    }

    static parseArgs(operation: Operation, str: string): [Term, string] {
        const argRaw = /^([^ ]+) ([^ ]+) ([^ ]+)/.exec(str)
        if(argRaw == null) {
            throw new Error("Unable to parse constant.")
        }

        const p1 = +argRaw[1];
        const p2 = +argRaw[2];
        const p3 = +argRaw[3];

        str = str.substr(argRaw[0].length)
        return [new ConstTerm(operation, p1, p2, p3), str];
    }

    public formatArgs(): string {
        return `${this.p1} ${this.p2} ${this.p3}`
    }
}
