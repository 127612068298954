@if(state | async; as state) {
    <div class="d-flex flex-row wrapper">
        @for (s of state | keyvalue; track s.key) {
            <div>
                <ng-template #icon>
                    @switch (s.key) {
                        @case ("KUANTIC") { <i class="fas fa-k {{s.value.status}}"></i> }
                        @case ("TESLA") { <mat-icon svgIcon="tesla" [class]="s.value.status"></mat-icon> }
                        @case ("WEBFLEET") { <mat-icon svgIcon="webfleet" [class]="s.value.status"></mat-icon> }
                        @default { <i class="fas fa-server {{s.value.status}}"></i> }
                    }
                </ng-template>
                <span #iconBtn (click)="selected = s.key" class="open-icon">
                    <ng-container [ngTemplateOutlet]="icon"></ng-container>
                </span>

                <ng-template cdk-connected-overlay
                             [cdkConnectedOverlayOpen] = "selected === s.key"
                             [cdkConnectedOverlayOrigin]="iconBtn"
                             [cdkConnectedOverlayPositions]="[
                    {
                        originX: 'center',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    }
                 ]"
                >
                    <div #popup class="overlay d-flex flex-column gap-2 align-items-center">
                        <div class="d-flex justify-content-between w-100">
                            <div class="big">
                                <ng-container [ngTemplateOutlet]="icon"></ng-container>
                            </div>
                            <div class="name">{{s.key}}</div>
                            <i class="fas fa-multiply fa-2x close-icon" (click)="selected = ''"></i>

                        </div>
                        <div [class]="s.value.status + ' state'">{{s.value.status}}</div>
                        <hr>
                        <div class="message">{{s.value.message}}</div>
                        <hr>
                        <div class="meta">
                            <ul>
                                @for(m of s.value.meta; track m.key) {
                                    <li><b>{{m.key}}</b> {{m.msg}}</li>
                                }
                            </ul>
                        </div>
                    </div>
                </ng-template>

            </div>
        }
    </div>

}

