import {Component, Input} from '@angular/core';
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ExecuteWebfleetAccountAddOrUpdate} from "@io-elon-common/frontend-api";
import {IEditForm} from "../../../shared/components/dialogs/edit-dialog/edit-dialog.component";

@Component({
  selector: 'app-edit-webfleet-account-dialog',
  standalone: true,
    imports: [
        MatFormField,
        MatInput,
        MatLabel,
        ReactiveFormsModule,
        FormsModule
    ],
  templateUrl: './edit-webfleet-account-dialog.component.html',
  styleUrl: './edit-webfleet-account-dialog.component.scss'
})
export class EditWebfleetAccountDialogComponent implements IEditForm<ExecuteWebfleetAccountAddOrUpdate> {

    @Input()
    public data!: ExecuteWebfleetAccountAddOrUpdate;

    validate(): string[] {
        return [];
    }

}

