import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Fleet, Load} from '@io-elon-common/frontend-api';
import {LoadService} from '../../service/load.service';
import {ToastrService} from 'ngx-toastr';
import {FleetService} from '../../../vehicle/service/fleet.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {MatDialog} from "@angular/material/dialog";

declare const Chart: any;
const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;

@Component({
    selector: 'app-load-config-tile',
    templateUrl: './load-config-tile.component.html',
    styleUrls: ['./load-config-tile.component.scss']
})
export class LoadConfigTileComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input()
    public load!: Load;

    private fleetIdSubscription?: Subscription;
    private fleet?: BehaviorSubject<Fleet | undefined>;

    constructor(
        private readonly dialog: MatDialog,
        private readonly elementRef: ElementRef,
        private readonly loadService: LoadService,
        private readonly toastr: ToastrService,
        private readonly fleetService: FleetService
    ) {
    }

    ngOnInit(): void {
        this.fleetIdSubscription = this.fleetService.selectedFleet.subscribe(sf => {
            if(sf !== undefined) {
                this.fleet = this.fleetService.get(sf);
            }
        })
    }
    public ngOnDestroy(): void {
        this.fleetIdSubscription?.unsubscribe();
    }
    public async ngAfterViewInit(): Promise<void> {
        if(this.load.prediction.length === 0) {
            return;
        }
        const data = this.getData();
        const config = this.getConfig(data);

        const ctx = (this.elementRef.nativeElement.querySelector('.canvas') as HTMLCanvasElement).getContext('2d');
        if (ctx) {
            // tslint:disable-next-line:no-unused-expression
            new Chart(ctx, config);
        }
    }

    private getData(): any {
        return {
            labels: ["", "", ""],
            datasets: [
                {
                    label: 'L1',
                    data: this.load.prediction.map(p => {
                        return {
                            x: p.start,
                            y: p.l1
                        };
                    }),
                    borderColor: "#F00",
                    backgroundColor: "#F00",
                },
                {
                    label: 'L2',
                    data: this.load.prediction.map(p =>{
                        return {
                            x: p.start,
                            y: p.l2
                        };
                    }),
                    borderColor: "#0F0",
                    backgroundColor: "#0F0",
                },
                {
                    label: 'L3',
                    data: this.load.prediction.map(p => {
                        return {
                            x: p.start,
                            y: p.l3
                        };
                    }),
                    borderColor: "#00F",
                    backgroundColor: "#00F",
                }
            ]
        }
    }

    private getConfig(data: any): any {
        let maxY = 0; let minY = 0;
        this.load.prediction.forEach(p => {
            maxY = Math.max(maxY, p.l1 || 0, p.l2 || 0, p.l3 || 0);
            minY = Math.min(minY, p.l1 || 0, p.l2 || 0, p.l3 || 0);
        });

        if(maxY === 0 && minY === 0) {
            maxY = 10000;
        }

        return {
            type: 'line',
            data,
            options: {
                animation: false,
                responsive: false,
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: (context: any) => this.formatDate(context[0].parsed.x)
                        }
                    },
                    legend: false,
                    title: false
                },
                scales: {
                    x: {
                        min: this.load.prediction.sort((p1, p2) => p1.start - p2.start)[0].start,
                        max: this.load.prediction.sort((p1, p2) => p2.start - p1.start)[0].start,
                        type: 'linear',
                        ticks: {
                            callback: (v: number) => {
                                return ((v / HOUR) % 2) === 0 ? this.formatDate(v) : '';
                            },
                            stepSize: HOUR
                        }
                    },
                    y: {
                        min: minY,
                        max: maxY,
                        ticks: {
                            callback: (v: number) => {
                                if(v >= 10000) {
                                    return (v / 1000).toFixed(0) + 'kW';
                                }
                                return (v / 1000).toFixed(1) + 'kW';
                            }
                        }
                    }
                }
            }
        };
    }


    public async handleEditLoad(load: Load): Promise<void> {
        const powerSupplies =  this.fleet?.getValue()?.base.powerSupplies;
        if(powerSupplies === undefined){
            this.toastr.warning("Keine Basis ausgewählt");
            return;
        }
        await this.loadService.showEditDialog(load, powerSupplies);
    }
    public async handleDeleteLoad(load: Load): Promise<void> {
        await this.loadService.showDeleteDialog(load,{});
    }

    private formatDate(val: number) {
        return new Date(val).toLocaleTimeString(navigator.language, {
            timeZone: 'utc',
            hour: '2-digit',
            minute: '2-digit',
        });
    }
}
