@if (show404) {
    <app-404 msg="Dongle nicht gefunden"></app-404>
} @else {
    <div *ngIf="(dongle | async) as dongle; else loading" class="d-flex flex-column align-items-stretch gap-3">
        <app-boxes [boxes]="getBoxes(dongle)"></app-boxes>
        <app-note-table [source]="dongle" class="with-background" [sourceType]="NoteRelatedObject.Dongle"></app-note-table>
    </div>
}

<ng-template #loading>
    <mat-spinner></mat-spinner>
    Loading...
</ng-template>
