<div *ngIf="!error; else errorTemplate">
    <ng-container *ngIf="(fleetOverview | async) as fleetOverview; else loading">
        <div *ngIf="(fleetReservations | async) as fleetReservations; else loading">
            <ng-container *ngIf="(evses | async) as evses; else loading">
                <app-boxes class="boxes-wrapper" [boxes]="fleetOverview.boxes"></app-boxes>

                <mat-card class="pt-2 pb-2" appearance="outlined" *ngIf="fleetOverview?.fleet?.rfidTrainingMode" style="text-align: center">
                    <h2><i class="fas fa-warning"></i> RFID Karten Lernen Modus aktiv <i class="fas fa-warning"></i></h2>
                    Es werden aktuell alle RFID Karten an den Ladepunkten akzeptiert und gespeichert.
                </mat-card>

                <app-meter-no-fleet-info></app-meter-no-fleet-info>

                <app-solar-system-no-fleet-info></app-solar-system-no-fleet-info>

                <app-powersupply-fuse-alert></app-powersupply-fuse-alert>

                <mat-card appearance="outlined" class="p-0" *ngIf="(activeChargingSessions |async) as activeChargingSessions">
                    <ng-container *ngIf="(fleet | async) as fleet">
                        <app-vehicle-table
                            [vehicles]="fleetOverview.vehicles"
                            [basis]="fleet.base"
                            [reservations]="fleetReservations"
                            [evses]="evses"
                            [activeChargingSessions]="activeChargingSessions"
                                           [fleetId]="fleet.id"
                        ></app-vehicle-table>
                    </ng-container>
                </mat-card>

                <div class="buttons d-flex flex-row justify-content-end gap-3">
                    <button [disabled]="!fleetOverview.canAddVehicle" mat-raised-button color="primary" (click)="newVehicle()">Neues Fahrzeug</button>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>

<ng-template #loading>
    Loading...
</ng-template>
<ng-template #errorTemplate>
    <h1>Hinweis</h1>
    <p>{{error}}</p>
</ng-template>
