import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {Evse, Load, Meter, MeterFormularLiveValues, PhaseRotation} from "@io-elon-common/frontend-api";
import {EvseService} from "../../../../evse/service/evse.service";
import {LoadService} from "../../../../loads/service/load.service";
import {MeterService} from "../../../service/meter.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {FleetService} from '../../../../vehicle/service/fleet.service';
import {ConstTerm, EvseTerm, Formula, LoadTerm, MeterTerm, Operation, Term, TermType} from "../../../Formula";

const EDITOR_HEIGHT_OPEN = "62px";

@Component({
    selector: 'app-edit-formula',
    templateUrl: './edit-formula.component.html',
    styleUrls: ['./edit-formula.component.scss']
})
export class EditFormulaComponent implements OnInit, OnChanges, OnDestroy {
    public readonly TermType = TermType;

    @Input()
    public name!: string;
    @Input()
    public formula!: string
    @Output()
    public formulaChange: EventEmitter<string> = new EventEmitter<string>();
    @Input()
    liveValues?: MeterFormularLiveValues | null;


    public formulaObj!: Formula
    public editIdx: number | null = null;
    public removeIdx: number = -1;
    public createTerm?: Term

    public parseError = false;
    public editorHeight: 0 | "62px" = 0;

    public allEvses: BehaviorSubject<Evse[] | undefined>;
    public allLoads: BehaviorSubject<Load[] | undefined>;
    public allMeters: BehaviorSubject<Meter[] | undefined>;
    private evseSubscription: Subscription;
    private loadSubscription: Subscription;
    private meterSubscription: Subscription;
    private selectedFleetSubsciption!: Subscription;
    private fleetId!: number;
    private basisId?: number;

    constructor(
        private readonly evseService: EvseService,
        private readonly loadService: LoadService,
        private readonly meterService: MeterService,
        private readonly ngZone: NgZone,
        private readonly fleetService: FleetService
    ) {
        this.allEvses = evseService.getAll();
        this.allLoads = loadService.getAll();
        this.allMeters = meterService.getAll();

        this.evseSubscription = this.allEvses.subscribe();
        this.loadSubscription = this.allLoads.subscribe();
        this.meterSubscription = this.allMeters.subscribe();
    }

    ngOnDestroy() {
        this.evseSubscription.unsubscribe();
        this.loadSubscription.unsubscribe();
        this.meterSubscription.unsubscribe();
        this.selectedFleetSubsciption.unsubscribe();
    }

    async ngOnInit(): Promise<void> {
        this.parse();
        this.selectedFleetSubsciption = this.fleetService.selectedFleet.subscribe(id => {
            if (id !== undefined) {
                this.fleetId = id;
                this.fleetService.getPromise(id).then(f => {
                    return this.basisId = f.base.id;
                });
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.parse();
        let edit = this.editIdx;
        if(edit !== null && edit !== -1) {
            this.editIdx = null;
            this.edit(edit);
        }
    }

    private parse(): void {
        try {
            this.formulaObj = Formula.parse(this.formula);
            this.parseError = false;
        } catch (exc) {
            console.error(exc);
            this.parseError = true;
        }
    }

    public remove1(idx: number): void {
        this.removeIdx = idx;
        setTimeout(() => this.ngZone.run( () => this.removeIdx = -1), 2000);
    }

    public remove2(idx: number): void {
        if(this.editIdx !== null) {
            if(idx < this.editIdx) {
                this.editIdx--;
            } else if(idx === this.editIdx) {
                this.editIdx = null;
            }
        }
        this.formulaObj?.terms.splice(idx, 1);
        this.removeIdx = -1;
        this.emitTerms();
    }

    public edit(idx: number): void {
        if(this.editIdx === idx) {
            this.editorHeight = 0;
            setTimeout(() => this.ngZone.run( () => this.editIdx = null), 500);
            return;
        }

        this.editIdx = idx;
        if(idx === -1) {
            this.createTerm = new ConstTerm(Operation.ADD, 0,0,0);
            this.editorHeight = EDITOR_HEIGHT_OPEN
        } else {
            setTimeout(() => this.ngZone.run( () => this.editorHeight = EDITOR_HEIGHT_OPEN), 10);
        }
    }

    public commit(): void {
        if(this.editIdx == null) {
            throw new Error("Unable to commit change, when editor is not visible. (editIdx === null)")
        }
        if(this.editIdx == -1) {
            this.formulaObj.terms.push(this.createTerm!);
            this.editIdx = null;
        } else {
            setTimeout(() => this.ngZone.run( () => this.editIdx = null), 500);
        }

        this.editorHeight = 0;
        this.emitTerms();
        this.parse();
    }

    public emitTerms(): void {
        const ret = this.formulaObj.toString();

        this.formula = ret;
        this.formulaChange.emit(ret);
    }


    public rotationImage(rotation: PhaseRotation): string {
        switch (rotation) {
            case 'NO_ROTATION':
                return "/assets/img/phase_rotation/no_rotate.png"
            case 'LEFT':
                return "/assets/img/phase_rotation/left.png"
            case 'RIGHT':
                return "/assets/img/phase_rotation/right.png"
            case 'SWITCH_1_2':
                return "/assets/img/phase_rotation/switch12.png"
            case 'SWITCH_1_3':
                return "/assets/img/phase_rotation/switch13.png"
            case 'SWITCH_2_3':
                return "/assets/img/phase_rotation/switch23.png"
            default:
                return "/assets/img/phase_rotation/empty.png"
        }
    }

    public meterName(meterId: number): string {
        return this.allMeters.value?.find(m => m.id === meterId)?.name || ("ID[" + meterId + "]");
    }

    public loadName(loadId: number): string {
        return this.allLoads.value?.find(m => m.id === loadId)?.name || ("ID[" + loadId + "]");
    }

    public changeType() {
        const oldTerm = this.editIdx === -1 ? this.createTerm! : this.formulaObj.terms[this.editIdx!]
        let newTerm: Term;

        const operation = oldTerm.operation;
        switch (oldTerm.type) {
            case TermType.METER_TERM:
                newTerm = new MeterTerm(operation, -1, PhaseRotation.NoRotation);
                break;
            case TermType.CONSTANT_TERM:
                newTerm = new ConstTerm(operation, 0, 0, 0);
                break;
            case TermType.EVSE_TERM:
                newTerm = new EvseTerm(operation, -1);
                break;
            case TermType.LOAD_TERM:
                newTerm = new LoadTerm(operation, -1, PhaseRotation.NoRotation);
                break;
        }

        if(this.editIdx === -1) {
            this.createTerm = newTerm;
        } else {
            this.formulaObj.terms[this.editIdx!] = newTerm;
        }
    }

    public filterAndSortEvses(evses: Evse[]): Evse[] {
        return evses.filter(e => e.basis.id === this.basisId)
                .sort((a, b) => a.name.localeCompare(b.name));
    }

    public filterAndSortMeter(meters: Meter[]): Meter[] {
        return meters.filter(m => m.basis !== undefined && m.basis.id === this.basisId)
        // @ts-ignore
        .sort((a, b) => a.name.localeCompare(b.name));
    }



    get liveResult(): {p1: string, p1Win: string, p2: string, p2Win: string, p3: string, p3Win: string} | null {
        if(!this.liveValues) {
            return null
        }

        return {
            p1 : this.formatPower(this.liveValues.result.p1),
            p1Win: this.formatPower(this.liveValues.result.p1Window, true),
            p2 : this.formatPower(this.liveValues.result.p2),
            p2Win: this.formatPower(this.liveValues.result.p2Window, true),
            p3 : this.formatPower(this.liveValues.result.p3),
            p3Win: this.formatPower(this.liveValues.result.p3Window, true),
        }
    }

    public formatPower(pwr: number, formatAsDelta = false) {
        let unit = "W"
        if(Math.abs(pwr) > 1000) {
            pwr /= 1000;
            unit = "kW"
        }

        if(!formatAsDelta || pwr < 0) {
            return pwr.toFixed(1) + unit
        } else {
            if(pwr === 0) {
                return "± 0W"
            }
            return "+" + pwr.toFixed(1) + unit
        }
    }

    public liveData(t: Term): {p1: string, p2: string, p3: string} | null {
        if(!this.liveValues) {
            return null;
        }

        const termData = this.liveValues.terms as Array<{term: string, p1: number, p2: number, p3: number}>;

        const termStr = t.toString();
        const found = termData.find(t1 => termStr === t1.term)

        if(!found) {
            return null;
        }

        return {
            p1 : this.formatPower(found.p1),
            p2 : this.formatPower(found.p2),
            p3 : this.formatPower(found.p3),
        }
    }
}
