import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AbstractTableComponent} from '../../../shared/components/tables/AbstractTableComponent';
import {MatPaginator} from '@angular/material/paginator';
import {WebfleetAccount} from '@io-elon-common/frontend-api';
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatTableDataSource
} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatIconButton} from '@angular/material/button';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIcon} from '@angular/material/icon';
import {WebfleetAccountService} from '../service/webfleet-account.service';

@Component({
  selector: 'app-webfleet-account-table',
  standalone: true,
    imports: [
        MatTable,
        MatColumnDef,
        MatHeaderCell,
        MatCell,
        MatHeaderCellDef,
        MatCellDef,
        MatHeaderRowDef,
        MatRowDef,
        MatRow,
        MatHeaderRow,
        MatPaginator,
        MatIconButton,
        MatTooltip,
        MatIcon
    ],
  templateUrl: './webfleet-account-table.component.html',
  styleUrl: './webfleet-account-table.component.scss'
})
export class WebfleetAccountTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input() public accounts!: WebfleetAccount[];

    public displayedColumns: string[] = ['id', 'name', 'actions'];
    public dataSource = new MatTableDataSource<WebfleetAccount>([]);
    public selection = new SelectionModel<WebfleetAccount>(true, []);

    public constructor(
        private readonly webfleetAccountService: WebfleetAccountService
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        this.dataSource = new MatTableDataSource(this.accounts);
        this.dataSource.paginator = this.paginator;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.accounts;
    }
    public async handleEditAccount(account: WebfleetAccount): Promise<void> {
        await this.webfleetAccountService.showEditDialog(account);
    }
    public async handleDeleteAccount(account: WebfleetAccount): Promise<void> {
        await this.webfleetAccountService.showDeleteDialog(account,{});
    }

    public async refreshToken(account: WebfleetAccount): Promise<void> {
        await this.webfleetAccountService.webfleetAccountLogin(account);
    }

    public async refreshVehicleList(account: WebfleetAccount): Promise<void> {
        await this.webfleetAccountService.updateVehicleList(account.id);
    }
}
