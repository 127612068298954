import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Vehicle, VehicleMini, VehicleTeaser} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs';
import {VehicleService} from '../../../../modules/vehicle/service/vehicle.service';
import {AuthService} from '../../../guards/auth.service';
import {RouterLink} from "@angular/router";
import {AsyncPipe, NgIf} from "@angular/common";
import {VehicleName} from "../../../helper/pipes";

@Component({
    selector: 'app-vehicle-link',
    standalone: true,
    imports: [
        RouterLink,
        AsyncPipe,
        VehicleName,
        NgIf
    ],
    templateUrl: './vehicle-link.component.html',
    styleUrls: ['./vehicle-link.component.scss']
})
export class VehicleLinkComponent implements OnInit, OnChanges{

    @Input()
    public vehicle: Vehicle | VehicleTeaser | VehicleMini | undefined;

    @Input()
    public vehicleId: number | undefined

    @Input()
    public showAlerts = true

    public vehicleToDisplay: BehaviorSubject<Vehicle | VehicleTeaser | VehicleMini | undefined> = new BehaviorSubject<Vehicle | VehicleTeaser | VehicleMini | undefined>(undefined)

    public fleetId: number | undefined;

    constructor(
        private readonly vehicleService: VehicleService,
        public readonly authService: AuthService
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.updateDisplayVehicle();
        const vid = this.vehicle ? this.vehicle.id : this.vehicleId;
        if (vid) {
            const v = await this.vehicleService.getPromise(vid, this.showAlerts);
            this.fleetId = v.fleet.id;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateDisplayVehicle();
    }

    private updateDisplayVehicle(): void {
        if (this.vehicle) {
            this.vehicleToDisplay.next(this.vehicle);
        } else if (typeof this.vehicleId === "number") {
            this.vehicleToDisplay = this.vehicleService.get(this.vehicleId, this.showAlerts) as unknown as BehaviorSubject<Vehicle | VehicleTeaser | VehicleMini | undefined>;
        } else {
            this.vehicleToDisplay.next(undefined)
        }
    }
}
