<div *ngIf="(permissionGroups | async) as permissionGroups; else loading">
    <table mat-table [dataSource]="permissionGroups" class="full-width-table" multiTemplateDataRows matSort>

        <!-- id Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="col-id">ID</th>
            <td mat-cell *matCellDef="let element" class="col-id">
                {{element.id}}
            </td>
        </ng-container>

        <!-- Username Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="col-username"> Gruppen Name</th>
            <td mat-cell *matCellDef="let element" class="col-username">
                {{element.name}}
            </td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="users">
            <th mat-header-cell *matHeaderCellDef class="col-username"> Benutzer</th>
            <td mat-cell *matCellDef="let element" class="col-username">
                {{getUsers(element)}}
            </td>
        </ng-container>


        <!-- Impl Column -->
        <ng-container matColumnDef="permissions">
            <th mat-header-cell *matHeaderCellDef class="col-name"> Berechtigungen</th>
            <td mat-cell *matCellDef="let element" class="col-name d-flex flex-row justify-content-start align-items-center">
                {{getPermissions(element)}}
                <button [disabled]="!element.canEdit" (click)="$event.stopPropagation(); add(element)" mat-icon-button matTooltip="Hinzufügen"><mat-icon fontSet="material-icons">add</mat-icon></button>
            </td>
        </ng-container>

        <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element">

        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedGroup">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="permissiongroup-element-detail"
               [@permissionsExpand]="isExpanded(element.id) ? 'expanded' : 'collapsed'">
                <mat-card appearance="outlined" class="p-0" style="width: 100%;">
                    <app-permission-table [permissions]="element.permissions" [permissionGroup]="element"></app-permission-table>
                    <div class="buttons d-flex flex-row justify-content-end gap-3 mb-3 me-3">
                        <button [disabled]="!element.canEdit" mat-raised-button color="primary" (click)="add(element)">Berechtigung hinzufügen</button>
                    </div>
                </mat-card>
          </div>
        </td>
      </ng-container>

        <!-- actions -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="col-actions"> </th>
            <td mat-cell *matCellDef="let element" class="col-actions">
                <button [disabled]="!element.canEdit" (click)="$event.stopPropagation(); edit(element)" mat-icon-button matTooltip="Bearbeiten"><mat-icon fontSet="material-icons">edit</mat-icon></button>
                <button [disabled]="!element.canDelete" (click)="$event.stopPropagation(); delete(element)" mat-icon-button matTooltip="Löschen"><mat-icon fontSet="material-icons">delete</mat-icon></button>
                <button mat-icon-button aria-label="expand row" matTooltip="Berechtigungen anzeigen" (click)="setExpandedPermissionId(element.id); $event.stopPropagation()">
                    <mat-icon *ngIf="!isExpanded(element.id)">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="isExpanded(element.id)">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns"
            class="permissiongroup-element-row"
            [class.permissiongroup-expanded-row]="isExpanded(element.id)"
            (click)="setExpandedPermissionId(element.id)">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedGroup']" class="permissiongroup-detail-row"></tr>
    </table>
    <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
</div>
<ng-template #loading>
    Loading...
</ng-template>
