<mat-form-field appearance="fill" style="width:50%">
    <mat-label>Name</mat-label>
    <input matInput [formControl]="name">
    <mat-error>{{name | validationErrorFormatter}}</mat-error>
</mat-form-field>

<hr>

<app-edit-formula [(formula)]="data.formula" [name]="data.name" (formulaChange)="formula.setValue($event)" [liveValues]="liveValues | async"></app-edit-formula>
<div *ngIf="isDev" style="width:100%">
    <mat-form-field appearance="fill" style="width:100%">
        <mat-label>Formel</mat-label>
        <input matInput [formControl]="formula">
        <mat-error>{{formula | validationErrorFormatter}}</mat-error>
    </mat-form-field>
</div>

<hr>
@if(settingsModified) {
    <div class="warn">
        Achtung, es wurden Experteneinstellungen geändert.
    </div>
}
@if(showExpertView) {
    <app-help-box [dialogType]="DialogType.HELP" style="max-width: 650px" [closable]="false">
        Hier kann das Verhalten der Formel konfiguriert werden, um die Reaktionszeit zu beschleunigen.
        Diese Einstellungen sind standardmäßig so gesetzt, dass eine hohe Zuverlässigkeit und wenig
        Fehler in der Ausgabe sind.
        <br><br>
        Um die dynamische Last mit der Formel zu berechnen, muss das System auf Messwerte von allen
        Messgeräten warten. Hier kann eingestellt werden, worauf nicht gewartet werden soll.
        <strong>Wichtig:</strong> Es werden trotzdem alle Änderungen der betroffenen Datenquellen
        berücksichtigt, es kann jedoch kurzzeitig zu fehlerhaften Ergebnissen in der Berechnung führen,
        wodurch Ladevorgänge möglicherweise unnötig gestoppt/gestartet und wieder gestartet/gestoppt werden.
        Wenn Informationen zu spät betrachtet werden, kann es jedoch dazu führen, dass das dynamische
        Lastmanagement zu langsam ist, und Sicherungen auslösen.
        <br><br>
        <strong>Nicht auf Ladepunkte ohne Fahrzeug warten:</strong> Wenn diese Option aktiv ist, wird
        beim Berechnen der dynamischen Last nicht auf Ladepunkte gewartet, an denen derzeit kein Fahrzeug
        eingesteckt ist. Es ist davon auszugehen, dass an diesen Stationen keine relevante Strom-Änderung zu
        erwarten ist, daher wird nicht auf Messwerte von diesen Ladepunkten gewartet. Diese Option ist besonders
        wichtig, wenn es im System OCPP-Stationen gibt, bei denen der Sendeintervall von Messwerten davon abhängt,
        ob ein Fahrzeug eingesteckt ist. <br><i>Standardmäßig aktiv</i>
        <br><br>
        <strong>Nicht auf Offline/Gestörte Ladepunkte warten:</strong> Wenn diese Option aktiv ist, wird beim
        Berechnen der dynamischen Last nicht auf Ladepunkte gewartet, die derzeit in einem Fehlerzustand sind
        (
        <mat-icon class="iblack" svgIcon="evse_broken"></mat-icon>
        <mat-icon class="iblack" svgIcon="evse_maintenance"></mat-icon>
        <mat-icon class="iblack" svgIcon="evse_on_repair"></mat-icon>
        ). Diese Option ist wichtig, da von Stationen, die in einem Fehlerzustand sind, wahrscheinlich auch keine
        Messwerte empfangen werden. <br><i>Standardmäßig aktiv</i>
        <br><br>
        <strong>Nicht auf Ladepunkte ohne Adjustment warten:</strong> Wenn diese Option aktiv ist, wird beim
        Berechnen der dynamischen Last nicht auf Ladepunkte gewartet, bei denen in letzter Zeit kein Adjustment
        durchgeführt worden ist. Diese Option kann helfen, das dynamische Lastmanagement zu beschleunigen, wenn
        Messwerte von den Ladepunkten nur in einer geringen Frequenz gesendet werden. Es ist jedoch mit
        Messfehlern zu rechen, wenn an Ladepunkten eine Änderung des Stromes stattfindet, die nicht durch
        IO-ELON ausgelöst worden ist (z.B. Wenn ein Akku vollständig geladen ist und das Fahrzeug deshalb
        keinen Storm mehr benötigt, wenn ein Lademanagement im Fahrzeug selbstständig steuert (ggf. Einstellungen
        in der App des Fahrzeugs)). <br><i>Standardmäßig deaktiviert</i>
    </app-help-box>
    <mat-checkbox [formControl]="closeNonPluggedEvse">
        Nicht auf Ladepunkte ohne Fahrzeug warten:
    </mat-checkbox>
    <mat-checkbox [formControl]="closeErrorEvse">
        Nicht auf Offline/Gestörte Ladepunkte warten:
    </mat-checkbox>
    <mat-checkbox [formControl]="closeNonAdjustedEvse">
        Nicht auf Ladepunkte ohne Adjustment warten:
    </mat-checkbox>
} @else {
    <span (click)="showExpertView = true" class="clickable"><i class="fas fa-gear"></i> Experteneinstellungen</span>
}
