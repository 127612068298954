import {Injectable, Pipe, PipeTransform} from '@angular/core';

interface Threshold {
    limit: number;
    factor: number;
    prefix: string;
}

const THRESHOLDS: Threshold[] = [
    {limit: 1e-12, factor: 1e15, prefix: 'f'},
    {limit: 1e-9, factor: 1e12, prefix: 'p'},
    {limit: 1e-6, factor: 1e9, prefix: 'n'},
    {limit: 1e-3, factor: 1e6, prefix: 'µ'},
    {limit: 1, factor: 1e3, prefix: 'm'},
    {limit: 1e3, factor: 1, prefix: ''},
    {limit: 1e6, factor: 1e-3, prefix: 'k'},
    {limit: 1e9, factor: 1e-6, prefix: 'M'},
    {limit: 1e12, factor: 1e-9, prefix: 'G'},
    {limit: 1e15, factor: 1e-12, prefix: 'T'},
    {limit: 1e18, factor: 1e-15, prefix: 'P'},
    {limit: 1e21, factor: 1e-18, prefix: 'E'}
];

@Pipe({
    name: 'eng',
    standalone: true,
    pure: true
})
@Injectable({
    providedIn: 'root'
})
export class EngPipe implements PipeTransform {

    transform(value: number, unit: string): string {
        if(value === 0) {
            return value + ` ${unit}`;
        }

        const absoluteValue = Math.abs(value);
        for (const {limit, factor, prefix} of THRESHOLDS) {
            if (absoluteValue < limit) {
                return this.formatWithSignificantDigits(value * factor) + ` ${prefix}${unit}`;
            }
        }
        return this.formatWithSignificantDigits(value) + ` ${unit}`;
    }

    private formatWithSignificantDigits(value: number): string {
        // Ensure the value is shown with 3 significant digits
        if (Math.abs(value) >= 100) {
            return value.toFixed(0); // No decimals
        } else if (Math.abs(value) >= 10) {
            return value.toFixed(1); // One decimal
        } else {
            return value.toFixed(2); // Two decimals
        }
    }
}
