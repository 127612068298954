import {Component, Input, OnChanges} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {ILookupHelper} from "../../../../app-routing.module";
import {EvseService} from "../../../../modules/evse/service/evse.service";
import {MeterService} from "../../../../modules/meter/service/meter.service";
import {VehicleService} from "../../../../modules/vehicle/service/vehicle.service";
import {Title} from "@angular/platform-browser";
import {RfidService} from '../../../../modules/rfid/service/rfid.service';
import {DongleService} from '../../../../modules/dongle/service/dongle.service';

export interface Breadcrumb {
    label: string;
    link: string;
}


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnChanges {

    breadcrumbs: Breadcrumb[] = [];
    @Input()
    selectedFleetName!: string;

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly evseService: EvseService,
        private readonly meterService: MeterService,
        private readonly vehicleService: VehicleService,
        private readonly rfidService: RfidService,
        private readonly dongleService: DongleService,
        private readonly titleService: Title,

    ) {
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                this.breadcrumbs = [];
                this.buildBreadcrumb().then(bc => {
                    this.breadcrumbs = bc
                    this.titleService.setTitle("IO-ELON | " + bc.map(e => e.label).join("▸"))
                });
            }
        });
    }

    ngOnChanges(): void {
        this.buildBreadcrumb().then(bc => {
            this.titleService.setTitle("IO-ELON | " + bc.map(e => e.label).join("▸"))
            return this.breadcrumbs = bc;
        });
    }

    async buildBreadcrumb(): Promise<Breadcrumb[]> {
        const breadcrumbs: Breadcrumb[] = [];

        let path = "";
        let r: ActivatedRoute | null = this.activatedRoute;
        while (r) {
            if(r.routeConfig?.path) {
                path += "/" + r.routeConfig?.path;
                const breadcrumbsRaw: (string | ((lf: ILookupHelper) => Promise<string>))[] = r.routeConfig?.data?.breadcrumb;
                if (breadcrumbsRaw) {
                    for (const bc of breadcrumbsRaw || ["???"]) {
                        if (typeof bc === "string") {
                            breadcrumbs.push({label: bc, link: path});
                        } else if (typeof bc === "function") {
                            const lookupHelper: ILookupHelper = {
                                lookupCurrentFleet: () => {
                                    return Promise.resolve(this.selectedFleetName);
                                },
                                lookupFleetByEvse: async (argName: string) => {
                                    try {
                                        const paramVal = this.getParamVal(this.activatedRoute, argName);
                                        if (paramVal) {
                                            let e = await this.evseService.getPromise(paramVal, false);
                                            return (e.basis.name || "---");
                                        } else {
                                            return Promise.resolve(this.selectedFleetName);
                                        }
                                    } catch {
                                        return Promise.resolve(this.selectedFleetName);
                                    }
                                },
                                lookupFleetByMeter: async (argName: string) => {
                                    try {
                                        const paramVal = this.getParamVal(this.activatedRoute, argName);
                                        if (paramVal) {
                                            let m = await this.meterService.getPromise(paramVal, false);
                                            return m.basis?.name || "---";
                                        } else {
                                            return Promise.resolve(this.selectedFleetName);
                                        }
                                    } catch {
                                        return Promise.resolve(this.selectedFleetName);
                                    }
                                },
                                lookupFleetByVehicle: async (argName: string) => {
                                    try {
                                        const paramVal = this.getParamVal(this.activatedRoute, argName);
                                        if (paramVal) {
                                            let v = await this.vehicleService.getPromise(paramVal, false);
                                            return v.fleet.name;
                                        } else {
                                            return Promise.resolve(this.selectedFleetName);
                                        }
                                    } catch {
                                        return Promise.resolve(this.selectedFleetName)
                                    }
                                },
                                lookupEvse: (argName: string) => this.evseService.getPromise(this.getParamVal(this.activatedRoute, argName), false).then(e => e.name).catch(e => "---"),
                                lookupMeter: (argName: string) => this.meterService.getPromise(this.getParamVal(this.activatedRoute, argName), false).then(m => m.name ?? m.id + "").catch(e => "---"),
                                lookupVehicle: (argName: string) => this.vehicleService.getPromise(this.getParamVal(this.activatedRoute, argName), false).then(v => v.name ?? v.id + "").catch(e => "---"),
                                lookupRfid: (argName: string) => this.rfidService.getPromise(this.getParamVal(this.activatedRoute, argName), false).then(v => v.name ?? v.id + "").catch(e => "---"),
                                lookupDongle: (argName: string) => this.dongleService.getPromise(this.getParamVal(this.activatedRoute, argName), false).then(v => v.name ?? v.id + "").catch(e => "---"),
                            }


                            breadcrumbs.push({label: await bc(lookupHelper), link: path})
                        }
                    }
                } else {
                    breadcrumbs.push({label: "????", link: path});
                }
            }
            r = r.firstChild;
        }

        return breadcrumbs;
    }

    private getParamVal(route: ActivatedRoute, paramName: string): any | null {
        let r: ActivatedRoute | null = route;
        while(r) {
            if(r!.snapshot.params[paramName]) {
                return r!.snapshot.params[paramName]
            }
            r = r.firstChild;
        }
        return null;
    }
}

